import React from 'react';
import keyBy from 'lodash/keyBy';

export const localStorageToken = 'auth-token';
export const defaultCurrency = 'CAD';

export const RouterContext = React.createContext();

export const PER_PAGE_OPTIONS = [
  { value: 10, label: 10 },
  { value: 30, label: 30 },
  { value: 100, label: 100 },
];

export const SORTS_OF_WINE_OPTIONS = [
  { value: undefined, label: 'All sorts' },
  { value: 'RED', label: 'Red' },
  { value: 'WHITE', label: 'White' },
  { value: 'ROSE', label: 'Rose' },
  { value: 'SPARKLING', label: 'Sparkling' },
  { value: 'FORTIFIED', label: 'Fortified' },
  { value: 'BUBBLES', label: 'Bubbles' },
];

export const perPagePagination = [10, 30, 100];

export const SORTS_OF_WINE = [
  'Red',
  'White',
  'Rose',
  'Sparkling',
  'Fortified',
  'Bubbles',
];
export const PERIOD_IN_MONTH = [
  'Monthly',
  'Quarterly',
  'Biannually',
  'Annually',
];

export const PERIOD_IN_MONTH_OPTIONS = [
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'QUARTERLY', label: 'Quarterly' },
  { value: 'BIANNUALLY', label: 'Biannually' },
  { value: 'ANNUALLY', label: 'Annually' },
];

export const VOLUME_UNIT = [
  { value: 'ml', label: 'ml' },
  { value: 'l', label: 'l' },
  { value: 'us_oz', label: 'oz' },
];
export const WEIGHT_UNIT = [{ value: 'lb', label: 'lb' }];

export const TRANSFER_TYPES = [
  { value: 'ArrivalTransfer', label: 'Add Inventory' },
  { value: 'WriteOffTransfer', label: 'Write-off' },
  {
    value: 'WarehouseToWarehouseTransfer',
    label: 'Warehouse to Warehouse Transfer',
  },
  { value: 'AdjustmentTransfer', label: 'Inventory adjustment' },
  {
    value: 'SaleTransfer',
    label: 'Sale',
  },
  {
    value: 'ContainerWorldWriteOffTransfer',
    label: 'Container World Write-Off',
  },
  {
    value: 'ContainerWorldArrivalTransfer',
    label: 'Container World Add Inventory',
  },
];

export const TRANSFERS_TYPES = Object.freeze({
  ArrivalTransfer: 'Add Inventory',
  WriteOffTransfer: 'Write-off',
  WarehouseToWarehouseTransfer: 'Warehouse to Warehouse',
  SaleTransfer: 'Sale',
  ContainerWorldWriteOffTransfer: 'Container World Write-Off',
  ContainerWorldArrivalTransfer: 'Container World Add Inventory',
  ContainerWorldArrivalTransferWriteOffTransfer:
    'Container World Add Inventory Write-Off',
  ContainerWorldWriteOffTransferWarehouseToWarehouseTransfer:
    'Container World Write-Off Warehouse To Warehouse',
});

export const ROLES = [
  { apiValue: 'staff', userValue: 'Staff' },
  { apiValue: 'owner', userValue: 'Owner' },
  { apiValue: 'manager', userValue: 'Manager' },
  { apiValue: 'host', userValue: 'Host' },
  { apiValue: 'customer', userValue: 'Customer' },
  { apiValue: 'sales_rep', userValue: 'Sales Rep' },
  { apiValue: 'bookkeeper', userValue: 'Bookkeeper' },
];

export const ORDER_STATUSES = [
  { apiValue: ['DRAFT', 'FULFILLED'], userValue: 'Order Created' },
  {
    apiValue: 'WAITING_FOR_APPROVAL',
    userValue: 'Waiting For Approval',
  },
  { apiValue: 'CUSTOMER_APPROVED', userValue: 'Customer Approved' },
  { apiValue: 'MANAGER_APPROVED', userValue: 'Manager Approved' },
  { apiValue: 'PAYMENT_ERROR', userValue: 'Payment Error' },
  { apiValue: 'PAID_BY_CARD', userValue: 'Paid by Card' },
  { apiValue: 'PAID_IN_CASH', userValue: 'Paid in Cash' },
  { apiValue: 'PAID_BY_CHEQUE', userValue: 'Paid by Cheque' },
  { apiValue: 'FINISHED', userValue: 'Finished' },
  { apiValue: 'SHIPPED', userValue: 'Shipped' },
  { apiValue: 'PARTIALLY_RETURNED', userValue: 'Partially Returned' },
  { apiValue: 'RETURNED', userValue: 'Returned' },
  { apiValue: 'CANCELLED', userValue: 'Cancelled' },
];

export const UNPAID_STATUSES = [
  'PAYMENT_ERROR',
  'FULFILLED',
  'DRAFT',
  'WAITING_FOR_PAYMENT',
];

export const PAID_STATUSES = [
  'PAID_BY_CARD',
  'PAID_BY_CHEQUE',
  'PAID_IN_CASH',
  'PAID_BY_MONEY_TRANSFER',
  'PAID_BY_MONEY_ORDER',
  'PAID_BY_ETRANSFER',
];

export const LEADS_STATUSES = [
  { apiValue: 'NEW', userValue: 'New' },
  { apiValue: 'OPEN', userValue: 'Open' },
  { apiValue: 'IN_PROGRESS', userValue: 'In Progress' },
  { apiValue: 'OPEN_DEAL', userValue: 'Open Deal' },
  { apiValue: 'UNQUALIFIED', userValue: 'Unqualified' },
  { apiValue: 'ATTEMPTED_TO_CONTACT', userValue: 'Attempted to Contact' },
];

export const HUMAN_ORDER_STATUSES = {
  DRAFT: 'Order Created',
  FULFILLED: 'Order Created',
  WAITING_FOR_PAYMENT: 'Waiting For Payment',
  WAITING_FOR_APPROVAL: 'Waiting For Approval',
  CUSTOMER_APPROVED: 'Customer Approved',
  MANAGER_APPROVED: 'Manager Approved',
  PAYMENT_ERROR: 'Payment Error',
  PAID: 'Paid',
  PAID_BY_CARD: 'Paid by Card',
  PAID_IN_CASH: 'Paid in Cash',
  PAID_BY_CASH: 'Paid in Cash',
  PAID_BY_CHEQUE: 'Paid by Cheque',
  PAID_BY_MONEY_TRANSFER: 'Paid by Money Transfer',
  FINISHED: 'Finished',
  SHIPPED: 'Shipped',
  PARTIALLY_RETURNED: 'Partially Returned',
  RETURNED: 'Returned',
  CANCELLED: 'Cancelled',
  SKIPPED: 'Skipped',
  PENDING_FULFILLMENT: 'Pending Fulfillment',
};

export const HUMAN_LEADS_STATUSES = {
  NEW: 'New',
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  OPEN_DEAL: 'Open Deal',
  UNQUALIFIED: 'Unqualified',
  ATTEMPTED_TO_CONTACT: 'Attempted to Contact',
  CONNECTED: 'Connected (Commercial Customer)',
};

export const ORDER_PAYMENT_OPTIONS = [
  { apiValue: 'CARD', userValue: 'Card' },
  {
    apiValue: 'CARD_ALTERNATIVE',
    userValue: 'Card (alternative payment merchant)',
  },
  { apiValue: 'CASH', userValue: 'Cash' },
  { apiValue: 'CHEQUE', userValue: 'Cheque' },
  { apiValue: 'TRANSFER', userValue: 'Money Transfer' },
];

export const RETAIL_ORDER_PAYMENT_OPTIONS = [
  { apiValue: 'CARD', userValue: 'Card' },
  {
    apiValue: 'CARD_ALTERNATIVE',
    userValue: 'Card (alternative payment merchant)',
  },
  { apiValue: 'CASH', userValue: 'Cash' },
];

export const ORDER_PAYMENT_STATUSES = {
  CASH: 'PAID_IN_CASH',
  CARD: 'PAID_BY_CARD',
  CARD_ALTERNATIVE: 'PAID_BY_CARD',
  CHEQUE: 'PAID_BY_CHEQUE',
  TRANSFER: 'PAID_BY_MONEY_TRANSFER',
};

export const CUSTOMER_PAYMENT_TYPES = Object.freeze({
  CARD: 'CARD',
  CASH: 'CASH',
  CHEQUE: 'CHEQUE',
  ETRANSFER: 'ETRANSFER',
  MONEY_ORDER: 'MONEY_ORDER',
});

export const ORDER_PAYMENT_ABBREVIATIONS = keyBy(ORDER_STATUSES, 'apiValue');

export const COMPANY_TYPES = [
  'DFS',
  'LIC',
  'LRS',
  'GRC',
  'MOS',
  'RAS',
  'TWS',
  'VQA',
  'WIN',
  'WAS',
];

export const SUBSCRIPTION_STATUSES = [
  { value: 'NEW', label: 'New' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'CANCELLED', label: 'Cancelled' },
];

export const ORDER_TYPES = [
  { apiValue: 'TastingRoomOrder', userValue: 'Tasting Room Order' },
  { apiValue: 'SubscriptionOrder', userValue: 'Subscription Order' },
  { apiValue: 'RetailOrder', userValue: 'Dashboard Order' },
  { apiValue: 'CommercialOrder', userValue: 'Commercial Order' },
  { apiValue: 'EcommerceOrder', userValue: 'Ecommerce Order' },
  { apiValue: 'ContainerWorldOrder', userValue: 'ContainerWorld Order' },
  { apiValue: 'ImportedOrder', userValue: 'Imported Order' },
];

export const ORDER_ITEMS_TYPES = {
  Wine: [
    { apiValue: 'ProductOrderItem', userValue: 'Product Order Item' },
    {
      apiValue: 'ProductSampleOrderItem',
      userValue: 'Product Sample Order Item',
    },
  ],
  Food: [{ apiValue: 'FoodOrderItem', userValue: 'Food Order Item' }],
  Merchandise: [
    { apiValue: 'ProductOrderItem', userValue: 'Product Order Item' },
  ],
};

export const MONTHS = [
  {
    value: undefined,
    label: 'Select a month',
  },
  {
    value: 'JANUARY',
    label: 'January',
  },
  {
    value: 'FEBRUARY',
    label: 'February',
  },
  {
    value: 'MARCH',
    label: 'March',
  },
  {
    value: 'APRIL',
    label: 'April',
  },
  {
    value: 'MAY',
    label: 'May',
  },
  {
    value: 'JUNE',
    label: 'June',
  },
  {
    value: 'JULY',
    label: 'July',
  },
  {
    value: 'AUGUST',
    label: 'August',
  },
  {
    value: 'SEPTEMBER',
    label: 'September',
  },
  {
    value: 'OCTOBER',
    label: 'October',
  },
  {
    value: 'NOVEMBER',
    label: 'November',
  },
  {
    value: 'DECEMBER',
    label: 'December',
  },
];

export const FEE_TYPES = Object.freeze({
  Wine: 'Wine',
  Food: 'Food',
  Merchandise: 'Merchandise',
});

export const FEE_TYPES_PRICE_SHOW = Object.freeze({
  Percents: 'Percent',
  Money: 'Money',
});

export const FEE_TITLE_TYPES = Object.freeze({
  PST: 'PST',
  GST: 'GST',
  HST: 'HST',
  BOTTLE_DEPOSIT: 'BOTTLE DEPOSIT',
  'State Sales Tax': 'State Sales Tax',
  'Local Government Sales Tax': 'Local Government Sales Tax',
});

export const FEE_PRICE_TYPES = Object.freeze({
  CAD: 'Money',
  Percent: 'Percents',
});

export const WRITE_OFF_REASON_TYPES = Object.freeze({
  poured: 'Poured',
  broken: 'Broken',
  inventory_adjustment: 'Inventory Adjustment',
  taster: 'Tasting',
  rep_samples: 'Rep samples',
  lab_samples: 'Lab samples',
  vqa_samples: 'VQA samples',
  award_samples: 'Award samples',
  damaged: 'Damaged',
  return_to_production: 'Return to production',
  theft_loss: 'Theft / Loss',
  other: 'Other',
});

export const TASTER_WRITE_OFF_REASON_TYPES = Object.freeze({
  taster: 'Taster',
});

export const REFUND_ITEM_TYPES = Object.freeze({
  NOT_CHOSEN: 0,
  RETURN: 1,
  VOID: 2,
});

export const ORDER_TYPES_ITEMS = Object.freeze({
  TastingRoomOrder: 'Tasting Room Order',
  RetailOrder: 'Dashboard Order',
  FoodOrderItem: 'Food Order Item',
  SubscriptionOrder: 'Subscription Order',
  CommercialOrder: 'Commercial Order',
  EcommerceOrder: 'Ecommerce Order',
  ContainerWorldOrder: 'ContainerWorld Order',
  TastingMenuOrderItem: 'Tasting Menu Order Item',
  ProductOrderItem: 'Product Order Item',
  ProductSampleOrderItem: 'Product Sample Order Item',
  Merchandise: 'Merchandise',
  Wine: 'Wine',
  ImportedOrder: 'Imported Order',
});

export const DEVICE_TYPES = ['TABLET', 'PRINTER', 'CAMERA'];

export const SHIPPING_STATUSES = Object.freeze({
  UNKNOWN: 'Unknown',
  PRE_TRANSIT: 'Pre-Transit',
  IN_TRANSIT: 'In Transit',
  OUT_FOR_DELIVERY: 'Out For Delivery',
  DELIVERED: 'Delivered',
  AVAILABLE_FOR_PICKUP: 'Available For Pickup',
  RETURN_TO_SENDER: 'Return To Sender',
  FAILURE: 'Failure',
  CANCELLED: 'Cancelled',
  ERROR: 'Error',
  RETURNED: 'Returned',
});

export const PAID_OPTIONS = [
  { label: 'Paid', value: true },
  { label: 'Unpaid', value: false },
];

export const PAYMENT_TYPES = {
  CARD: { apiValue: 'CARD', userValue: 'CARD' },
  CARD_ALTERNATIVE: { apiValue: 'CARD_ALTERNATIVE', userValue: 'CARD' },
  CASH: { apiValue: 'CASH', userValue: 'CASH' },
};

export const PAYMENT_TYPES_FOR_COMMERCIAL_ORDERS = {
  CARD: { apiValue: 'CARD', userValue: 'CARD' },
  CARD_ALTERNATIVE: {
    apiValue: 'CARD_ALTERNATIVE',
    userValue: 'CARD (ALTERNATIVE PAYMENT MERCHANT)',
  },
  CASH: { apiValue: 'CASH', userValue: 'CASH' },
  CHEQUE: { apiValue: 'CHEQUE', userValue: 'CHEQUE' },
  TRANSFER: { apiValue: 'TRANSFER', userValue: 'MONEY TRANSFER' },
};

export const W_TO_W_STATUSES = Object.freeze({
  COMPLETED: 'Completed',
  PENDING_RECEIPT: 'Pending Receipt',
  DISPUTED: 'Disputed',
});

export const WINE_DIRECT_STATUSES = Object.freeze({
  FAILED: 'FAILED',
  SUCCESSFUL: 'SUCCESSFUL',
  INITIALIZED: 'INITIALIZED',
});

export const EXCISE_LICENSE_TYPES = [
  { id: 'WINE', title: 'Wine' },
  { id: 'USER', title: 'User' },
  { id: 'WAREHOUSE', title: 'Warehouse' },
];

export const WINE_SOURCE_TYPES = [
  {
    label: 'Bottled from Bulk',
    value: 'PACKAGING_ACTIVITIES_PACKAGED',
  },
  {
    label: 'Purchased from Licensee',
    value: '',
  },
];

export const PAID_STATUS_OPTIONS = [
  { value: 'PAID', label: 'Paid' },
  { value: 'UNPAID', label: 'Unpaid' },
];

export const RESOURCE_TYPES = [
  { value: undefined, label: 'All types' },
  {
    value: 'Wine',
    label: 'Wine',
  },
  { value: 'Food', label: 'Food' },
  { value: 'Merchandise', label: 'Merchandise' },
];

export const ORDER_TYPES_OPTIONS = [
  {
    label: 'Commercial Order',
    value: 'commercialorder',
  },
  {
    label: 'Dashboard Order',
    value: 'retailorder',
  },
  {
    label: 'Ecommerce Order',
    value: 'ecommerceorder',
  },
  {
    label: 'Subscription Order',
    value: 'subscriptionorder',
  },
  {
    label: 'Tasting Room Order',
    value: 'tastingroomorder',
  },
  {
    label: 'ContainerWorld Order',
    value: 'containerworldorder',
  },
  {
    label: 'Imported Order',
    value: 'importedorder',
  },
];
