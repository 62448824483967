export const ORDER_STATUS = Object.freeze({
  DRAFT: 'DRAFT',
  FULFILLED: 'FULFILLED',
  WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
  WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
  CUSTOMER_APPROVED: 'CUSTOMER_APPROVED',
  MANAGER_APPROVED: 'MANAGER_APPROVED',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  PAID_BY_CARD: 'PAID_BY_CARD',
  PAID_IN_CASH: 'PAID_IN_CASH',
  PAID_BY_CASH: 'PAID_BY_CASH',
  PAID_BY_CHEQUE: 'PAID_BY_CHEQUE',
  PAID_BY_MONEY_TRANSFER: 'PAID_BY_MONEY_TRANSFER',
  FINISHED: 'FINISHED',
  SHIPPED: 'SHIPPED',
  PARTIALLY_RETURNED: 'PARTIALLY_RETURNED',
  RETURNED: 'RETURNED',
  CANCELLED: 'CANCELLED',
  SKIPPED: 'SKIPPED',
  PENDING_FULFILLMENT: 'PENDING_FULFILLMENT',
});

export const ORDER_RESOURCE_TYPE = Object.freeze({
  TASTING_ROOM: 'TastingRoomOrder',
  SUBSCRIPTION: 'SubscriptionOrder',
  RETAIL: 'RetailOrder',
  COMMERCIAL: 'CommercialOrder',
  ECOMMERCE: 'EcommerceOrder',
  CONTAINER_WORLD: 'ContainerWorldOrder',
  IMPORTED: 'ImportedOrder',
});

export const ORDER_STATUS_FOR_EDIT = Object.freeze([
  'DRAFT',
  'FULFILLED',
  'WAITING_FOR_APPROVAL',
  'CUSTOMER_APPROVED',
  'MANAGER_APPROVED',
  'PAYMENT_ERROR',
  'WAITING_FOR_PAYMENT',
]);
