import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { Row, Col } from 'reactstrap';
import useSelector from 'hooks/useSelector';
import PageWrapper from 'components/PageWrapper';
import NotesTable from 'components/NotesTable';
import ActivityTable from 'components/ActivityTable';
import { actions as organizationsActions } from 'models/organizations/slice';
import { isOnlySingleRole } from 'utils/isOnlySingleRole';
import { PERMISSION_TYPES } from 'constants/permissions';

import {
  organizationSelector,
  isPendingSelector,
  isPendingNotesSelector,
  isPendingActivitiesSelector,
} from 'models/organizations/selectors';
import {
  rolesSelector,
  hasOwnerOrManagerRoleSelector,
} from 'models/user/selectors';
import Preloader from 'components/Preloader';
import Breadcrumbs from 'components/Breadcrumbs';
import isEmpty from 'lodash/isEmpty';
import { getNormalText } from 'utils/getNormalText';
import InfoSection from 'components/InfoSection/InfoSection';

const LeadsShow = () => {
  const { id } = useParams();
  const fetchLead = useAction(organizationsActions.fetchOrganization);
  const lead = useSelector(organizationSelector);
  const pending = useSelector(isPendingSelector);
  const roles = useSelector(rolesSelector);

  const isSalesRep = isOnlySingleRole(roles, PERMISSION_TYPES.SALES_REP);
  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);
  const pendingNotes = useSelector(isPendingNotesSelector);
  const pendingActivities = useSelector(isPendingActivitiesSelector);

  const isEditLinkVisible = isSalesRep || isOwnerOrManagerRole;

  useEffect(() => {
    fetchLead(id);
  }, [fetchLead, id, pendingNotes, pendingActivities]);

  if (pending) return <Preloader />;

  return (
    <PageWrapper>
      {isEditLinkVisible && (
        <Breadcrumbs
          link={`/lead/${lead?.id}/edit`}
          title={`Edit ${lead?.title} #${lead?.id}`}
          breadcrumbItems={[
            {
              title: 'Back to Leads',
              link: '/leads/',
              withSearch: true,
            },
          ]}
        />
      )}
      {!isEmpty(lead) && (
        <>
          <Row>
            <Col lg="6">
              <InfoSection
                title="Lead Details"
                gap="5"
                data={[
                  { key: 'Title', value: lead.title },
                  { key: 'License Number', value: lead.license_number },
                  { key: 'Company Type', value: lead.company_type },
                  { key: 'Price Type', value: getNormalText(lead.price_type) },
                  ...(lead?.default_warehouse
                    ? [
                        {
                          key: 'Default Warehouse',
                          value: lead.default_warehouse.title,
                        },
                      ]
                    : []),
                ]}
              />
            </Col>

            <Col lg="6">
              <InfoSection
                title="Payment Contact Information"
                gap="5"
                data={[
                  {
                    key: 'Name',
                    value: `${lead.contact_person.first_name} ${lead.contact_person.last_name}`,
                  },
                  { key: 'Email', value: lead.contact_person.email },
                  { key: 'Phone', value: lead.contact_person.phone || '-' },
                  {
                    key: 'Address',
                    value: Object.values(lead?.address).join(' '),
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <InfoSection
                title="Notes"
                gap="5"
                data={[
                  {
                    key: '',
                    value: <NotesTable notes={lead?.notes ?? []} isLead />,
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <InfoSection
                title="Activities"
                gap={10}
                data={[
                  {
                    key: '',
                    value: (
                      <ActivityTable activities={lead?.activities ?? []} />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      )}
    </PageWrapper>
  );
};

export default LeadsShow;
