import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import InfoSection from 'components/InfoSection/InfoSection';
import { prepareAddress } from 'utils/prepareAddress';

const Main = ({ exciseLicense }) => {
  const basicInfo = [
    { key: 'Brand', value: exciseLicense?.brand?.title },
    { key: 'Business Name', value: exciseLicense?.business_name },
    { key: 'Account Number', value: exciseLicense?.account_number },
    {
      key: 'Address',
      value: prepareAddress({
        country: exciseLicense?.country,
        state: exciseLicense?.state,
        city: exciseLicense?.city,
        line1: exciseLicense?.line1,
        line2: exciseLicense?.line2,
        postal_code: exciseLicense?.postal_code,
      }),
    },
  ];

  const licenseInfo = [
    { key: 'License Type', value: exciseLicense?.license_type },
    ...(exciseLicense?.license_type === 'WAREHOUSE'
      ? [{ key: 'Warehouse', value: exciseLicense?.warehouse?.title }]
      : []),
    {
      key: 'Wine Duty Rate under 1.2%',
      value: exciseLicense?.wine_duty_rate_0,
    },
    { key: 'Wine Duty Rate under 7%', value: exciseLicense?.wine_duty_rate_1 },
    { key: 'Wine Duty Rate over 7%', value: exciseLicense?.wine_duty_rate_7 },
    ...(exciseLicense?.license_type !== 'WINE'
      ? [
          {
            key: 'Spirits Duty Rate under 7%',
            value: exciseLicense?.spirit_duty_rate_under_7,
          },
          {
            key: 'Spirits Duty Rate over 7%',
            value: exciseLicense?.spirit_duty_rate_over_7,
          },
          {
            key: 'Spirits Special Duty Rate',
            value: exciseLicense?.spirit_duty_rate_special,
          },
        ]
      : []),
  ];

  return (
    <>
      <Row>
        <Col lg="6">
          <InfoSection title="Basic Information" data={basicInfo} gap="1.5" />
        </Col>
        <Col lg="6">
          <InfoSection
            title="License Information"
            data={licenseInfo}
            gap="1.5"
          />
        </Col>
      </Row>
    </>
  );
};

Main.propTypes = {
  exciseLicense: PropTypes.object,
};

export default Main;
