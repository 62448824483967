import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Table } from 'reactstrap';
import InfoSection from 'components/InfoSection/InfoSection';

const formatRowTitle = title => {
  return title
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
};

const UserReturnShowForm = ({ data }) => {
  const {
    period_start,
    period_end,
    due_date,
    wine_seven_percent_return_detail,
    wine_one_percent_return_detail,
    wine_zero_percent_return_detail,
    wine_seven_percent_quantity,
    wine_one_percent_quantity,
    wine_zero_percent_quantity,
    wine_seven_percent_duty_payable,
    wine_one_percent_duty_payable,
    wine_zero_percent_duty_payable,
    spirits_more_than_seven_percent_return_detail,
    spirits_not_more_than_seven_percent_return_detail,
    spirits_more_than_seven_percent_quantity,
    spirits_not_more_than_seven_percent_quantity,
    spirits_more_than_seven_percent_duty_payable,
    spirits_not_more_than_seven_percent_duty_payable,
    restricted_formulations_detail,
  } = data;

  const wineSections = [
    {
      title: 'Opening Inventory',
      rows: ['opening_inventory'],
    },
    {
      title: 'Additions to wine',
      rows: ['domestic', 'imported', 'total_additions'],
    },
    {
      title: 'Reductions from wine inventory',
      rows: [
        'used_in_restricted_formulation',
        'used_in_other',
        'used_to_produce_vinegar',
        'blended_with_spirits',
        'returned_to_licensee',
        'exported',
        'used_in_approved_processes',
        'removed_for_other_purposes',
        'total_reductions',
      ],
    },
    {
      title: 'Inventory Adjustment',
      rows: ['inventory_adjustment'],
    },
    {
      title: 'Closing Inventory',
      rows: ['closing_inventory'],
    },
  ];

  const spiritsSections = [
    {
      title: 'Opening Inventory',
      rows: ['opening_inventory'],
    },
    {
      title: 'Additions to inventory',
      rows: ['domestic', 'imported', 'total_additions'],
    },
    {
      title: 'Reductions from inventory',
      rows: [
        'used_in_restricted_formulation',
        'used_in_other',
        'used_to_produce_vinegar',
        'used_to_fortify_wine',
        'returned_to_licensee',
        'exported',
        'used_in_approved_processes',
        'removed_for_other_purposes',
        'total_reductions',
      ],
    },
    {
      title: 'Inventory Adjustment',
      rows: ['inventory_adjustment'],
    },
    {
      title: 'Closing Inventory',
      rows: ['closing_inventory'],
    },
  ];

  const restrictedFormulationsSections = [
    {
      title: 'Opening Inventory',
      rows: ['opening_inventory'],
    },
    {
      title: 'Additions to inventory',
      rows: [
        'restricted_formulation_produced',
        'received_from_licensed_users',
        'imported_restricted_formulation',
        'total_additions',
      ],
    },
    {
      title: 'Reductions from inventory',
      rows: [
        'used_in_an_approved_formulation_restricted_formulation',
        'used_in_an_approved_formulation_other',
        'removed_to_another_licensed_user',
        'exported',
        'total_reductions',
      ],
    },
    {
      title: 'Inventory Adjustment',
      rows: ['inventory_adjustment'],
    },
    {
      title: 'Closing Inventory',
      rows: ['closing_inventory'],
    },
  ];

  const renderWineDetailSection = section => {
    return (
      <Card key={section.title}>
        <CardBody>
          <h4 className="card-title mb-4">{section.title}</h4>
          <Table bordered responsive>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>7%</th>
                <th>1.2%+</th>
                <th>Less than 1.2%</th>
              </tr>
            </thead>
            <tbody>
              {section.rows.map(row => (
                <tr key={row}>
                  <th>{formatRowTitle(row)}</th>
                  <td>{wine_seven_percent_return_detail[row]}</td>
                  <td>{wine_one_percent_return_detail[row]}</td>
                  <td>{wine_zero_percent_return_detail[row]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  const renderSpiritsDetailSection = section => {
    return (
      <Card key={section.title}>
        <CardBody>
          <h4 className="card-title mb-4">{section.title}</h4>
          <Table bordered responsive>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '30%' }} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>7%</th>
                <th>Less than 7%</th>
              </tr>
            </thead>
            <tbody>
              {section.rows.map(row => (
                <tr key={row}>
                  <th>{formatRowTitle(row)}</th>
                  <td>{spirits_more_than_seven_percent_return_detail[row]}</td>
                  <td>
                    {spirits_not_more_than_seven_percent_return_detail[row]}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  const renderRestrictedFormulationsDetailSection = section => {
    return (
      <Card key={section.title}>
        <CardBody>
          <h4 className="card-title mb-4">{section.title}</h4>
          <Table bordered responsive>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: '40%' }} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>Litres</th>
              </tr>
            </thead>
            <tbody>
              {section.rows.map(row => (
                <tr key={row}>
                  <th>{formatRowTitle(row)}</th>
                  <td>{restricted_formulations_detail[row]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  const renderWineDutyPayableAndQuantity = () => {
    return (
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Duty Payable and Quantity (Wine)</h4>
          <Table bordered responsive>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>7%</th>
                <th>1.2%+</th>
                <th>Less than 1.2%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Quantity</th>
                <td>{wine_seven_percent_quantity}</td>
                <td>{wine_one_percent_quantity}</td>
                <td>{wine_zero_percent_quantity}</td>
              </tr>
              <tr>
                <th>Duty Payable</th>
                <td>{wine_seven_percent_duty_payable}</td>
                <td>{wine_one_percent_duty_payable}</td>
                <td>{wine_zero_percent_duty_payable}</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  const renderSpiritsDutyPayableAndQuantity = () => {
    return (
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">
            Duty Payable and Quantity (Spirits)
          </h4>
          <Table bordered responsive>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '30%' }} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>7%</th>
                <th>Less than 7%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Quantity</th>
                <td>{spirits_more_than_seven_percent_quantity}</td>
                <td>{spirits_not_more_than_seven_percent_quantity}</td>
              </tr>
              <tr>
                <th>Duty Payable</th>
                <td>{spirits_more_than_seven_percent_duty_payable}</td>
                <td>{spirits_not_more_than_seven_percent_duty_payable}</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  //   Actual return rendering
  return (
    <div>
      {/* This is the dates */}
      <Row>
        <Col lg="6" md="12" xl="6">
          <InfoSection
            title="Dates"
            gap="2"
            data={[
              { key: 'Period Start', value: period_start },
              { key: 'Period End', value: period_end },
              { key: 'Due Date', value: due_date },
            ]}
          />
        </Col>
      </Row>
      {/* Here is the start of the returns */}
      <Row>
        {/* Here is the user wine return */}
        <Col md={12} xl={6}>
          <h6 className="mb-4">Wine</h6>
          {wineSections.map(section => renderWineDetailSection(section))}
          {renderWineDutyPayableAndQuantity()}
          {/* Here is the user spirits return */}
          <h6 className="mb-4">Spirits</h6>
          {spiritsSections.map(section => renderSpiritsDetailSection(section))}
          {renderSpiritsDutyPayableAndQuantity()}
          {/* Here is the user restricted formulations section */}
          <h6 className="mb-4">Restricted Formulations</h6>
          {restrictedFormulationsSections.map(section =>
            renderRestrictedFormulationsDetailSection(section)
          )}
        </Col>
      </Row>
    </div>
  );
};

UserReturnShowForm.propTypes = {
  data: PropTypes.object.isRequired,
};

export default UserReturnShowForm;
