import React from 'react';
import ActionDropdown from 'components/ActionDropdown';
import PropTypes from 'prop-types';
import { PAID_STATUSES } from 'constants';
import { ORDER_STATUS, ORDER_RESOURCE_TYPE } from 'constants/orders';

const DropdownItemId = Object.freeze({
  PRINT: 'print',
  DOWNLOAD: 'download',
  EDIT: 'edit',
  FULFILL: 'fulfill',
  SENDORDER: 'sendorder',
  SHIP: 'ship',
});

const OrderActionDropdown = ({
  order,
  isSubscriptionOrder,
  onPrintPackageSlip,
  isDisabled,
  onFulfill,
  onSendOrder,
  onDownload,
  onShip,
}) => {
  const isCommercialOrder =
    order.resourcetype === ORDER_RESOURCE_TYPE.COMMERCIAL;
  const isRetailOrder = order.resourcetype === ORDER_RESOURCE_TYPE.RETAIL;
  const isTastingRoomOrder =
    order.resourcetype === ORDER_RESOURCE_TYPE.TASTING_ROOM;

  const isFulfilled = order.status === 'FULFILLED';
  const isPaidByCard = order.status === 'PAID_BY_CARD';

  const isStatusPaid = status => PAID_STATUSES.includes(status);
  const isPendingFulfilled =
    order.status === ORDER_STATUS.PENDING_FULFILLMENT ||
    isStatusPaid(order.status);
  const dropdownItem = [
    {
      id: DropdownItemId.EDIT,
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
      href: `/orders/${order.id}/edit`,
      isHidden: !(isFulfilled && isRetailOrder) || isPendingFulfilled,
    },
    {
      id: DropdownItemId.SHIP,
      icon: <i className="mdi mdi-send mr-2 font-size-18" />,
      label: 'Ship Order',
      isHidden: !isPendingFulfilled || isTastingRoomOrder,
    },
    {
      id: DropdownItemId.PRINT,
      icon: <i className="mdi mdi-package-variant mr-2 font-size-18" />,
      label: 'Print Packing Slip',
    },
    {
      id: DropdownItemId.FULFILL,
      icon: <i className="mdi mdi-truck-check mr-2 font-size-18" />,
      label: 'Fulfill Order',
      isHidden: !isPendingFulfilled || isTastingRoomOrder,
    },
    {
      id: DropdownItemId.DOWNLOAD,
      icon: <i className="mdi mdi-cloud-download-outline mr-2 font-size-18" />,
      label: 'Download',
      isHidden: !isCommercialOrder || isPendingFulfilled,
    },
    {
      id: DropdownItemId.SENDORDER,
      label: 'Send Order',
      isHidden: !(isSubscriptionOrder && isPaidByCard) || isPendingFulfilled,
    },
  ];

  const onItemClickHandler = item => {
    const itemHandlers = {
      [DropdownItemId.PRINT]: onPrintPackageSlip,
      [DropdownItemId.FULFILL]: onFulfill,
      [DropdownItemId.SENDORDER]: onSendOrder,
      [DropdownItemId.DOWNLOAD]: onDownload,
      [DropdownItemId.SHIP]: onShip,
    };

    if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  return (
    <ActionDropdown
      items={dropdownItem}
      onItemClick={onItemClickHandler}
      isDisabled={isDisabled}
      right
    />
  );
};

OrderActionDropdown.propTypes = {
  order: PropTypes.object.isRequired,
  isSubscriptionOrder: PropTypes.bool,
  onPrintPackageSlip: PropTypes.func,
  onSendOrder: PropTypes.func,
  onDownload: PropTypes.func,
  isDisabled: PropTypes.bool,
  onFulfill: PropTypes.func,
  onShip: PropTypes.func,
};

export default OrderActionDropdown;
