import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import InfoSection from 'components/InfoSection/InfoSection';
import { formatDate } from 'utils/formatDate';
import { projects } from 'constants/projects';

const Main = ({ code }) => {
  const basicInfo = [
    { key: 'Title', value: code?.title },
    {
      key: 'Brand',
      value: (
        <Link to={`/brands/${code?.brand?.id}/edit`}>{code?.brand?.title}</Link>
      ),
    },
    { key: 'Description', value: code?.description },
    ...(code?.coupon ? [{ key: 'Coupon', value: code?.coupon }] : []),
    { key: 'Discount Rate', value: `${code?.percentage_amount}%` },
    {
      key: 'Minimum Spend Amount',
      value: code?.minimum_spend_amount ?? '-',
    },
  ];

  const categorizedInfo = [
    ...(code?.warehouses?.length
      ? [
          {
            key: 'Warehouses',
            value: code?.warehouses
              .map(warehouse => warehouse.title)
              .join(', '),
          },
        ]
      : []),
    ...(code?.included_categories?.length
      ? [
          {
            key: 'Included Categories',
            value: code?.included_categories
              .map(category => category.title)
              .join(', '),
          },
        ]
      : []),
    ...(code?.excluded_categories?.length
      ? [
          {
            key: 'Excluded Categories',
            value: code?.excluded_categories
              .map(category => category.title)
              .join(', '),
          },
        ]
      : []),
    ...(code?.included_skus?.length
      ? [
          {
            key: 'Included SKUs',
            value: code?.included_skus.map(sku => sku.title).join(', '),
          },
        ]
      : []),
    ...(code?.excluded_skus?.length
      ? [
          {
            key: 'Excluded SKUs',
            value: code?.excluded_skus.map(sku => sku.title).join(', '),
          },
        ]
      : []),
  ];

  const additionalInfo = [
    ...(code?.coupon
      ? [
          {
            key: 'Where Code Can Be Applied',
            value:
              projects?.find(
                project => project?.value === code?.redeemable_location
              )?.label || projects[0]?.label,
          },
          {
            key: 'Use once per customer (Does not limit usage by Guests)',
            value: code?.use_once ? 'Yes' : 'No',
          },
          {
            key: 'Specific Customer',
            value: code?.customer?.id ? (
              <Link to={`/wineclub-customers/${code.customer.id}`}>
                {code.customer.first_name} {code.customer.last_name}
              </Link>
            ) : (
              'All customers'
            ),
          },
        ]
      : []),
  ];

  const dateInfo = [
    {
      key: 'Start Date',
      value: code?.promotion_start_date
        ? formatDate(code?.promotion_start_date, 'yyyy-MM-dd')
        : '-',
    },
    {
      key: 'End Date',
      value: code?.promotion_end_date
        ? formatDate(code?.promotion_end_date, 'yyyy-MM-dd')
        : '-',
    },
    { key: 'Created At', value: formatDate(code?.created_at) },
    { key: 'Updated At', value: formatDate(code?.updated_at) },
  ];

  return (
    <>
      <Row>
        <Col lg="6">
          <InfoSection title="Basic Info" data={basicInfo} gap="1.5" />
        </Col>
        {categorizedInfo.length > 0 && (
          <Col lg="6">
            <InfoSection
              title="Categorized Info"
              data={categorizedInfo}
              gap="1.5"
            />
          </Col>
        )}
        {additionalInfo.length > 0 && (
          <Col lg="6">
            <InfoSection
              title="Additional Info"
              data={additionalInfo}
              gap="1.5"
            />
          </Col>
        )}

        <Col lg="6">
          <InfoSection title="Dates Info" data={dateInfo} gap="1.5" />
        </Col>
      </Row>
    </>
  );
};

Main.propTypes = {
  code: PropTypes.object,
};

export default Main;
