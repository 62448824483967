import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useSelector from 'hooks/useSelector';
import { pendingFulfillmentOrdersPaginationSelector } from 'models/orders/selectors';
import useAction from 'hooks/useAction';
import { actions as ordersActions } from 'models/orders/slice';
import {
  DTC_PENDING_ORDER_TYPES,
  DTC_PENDING_ORDER_STATUSES,
} from 'pages/OrdersSummary/constants';

const DTCOrdersTitle = ({ title }) => {
  // Actions
  const fetchPendingFulfillmentAllOrders = useAction(
    ordersActions.fetchPendingFulfillmentAllOrdersPagination
  );

  // Selectors
  const pagination = useSelector(pendingFulfillmentOrdersPaginationSelector);

  // Effects
  useEffect(() => {
    fetchPendingFulfillmentAllOrders({
      resourcetypes: DTC_PENDING_ORDER_TYPES.map(type => type.value),
      statuses: DTC_PENDING_ORDER_STATUSES.map(type => type.value),
    });
  }, [fetchPendingFulfillmentAllOrders]);

  // Constants
  const totalCount = pagination?.total_count || null;

  return (
    <span>
      {title}
      {totalCount && (
        <span className="badge badge-light ml-2">{totalCount}</span>
      )}
    </span>
  );
};

DTCOrdersTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DTCOrdersTitle;
