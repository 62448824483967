import React from 'react';
import ActionDropdown from 'components/ActionDropdown';
import PropTypes from 'prop-types';
import { ORDER_STATUS, ORDER_RESOURCE_TYPE } from 'constants/orders';
import { PAID_STATUSES } from 'constants';

const DropdownItemId = Object.freeze({
  PRINT: 'print',
  DOWNLOAD: 'download',
  COMPLETE: 'complete',
  EDIT: 'edit',
  FULFILL: 'fulfill',
});

const CommercialActionDropdown = ({
  order,
  onPrintPackageSlip,
  onDownload,
  onComplete,
  isDisabled,
  onFulfill,
  onShip,
}) => {
  const isCommercialOrder =
    order.resourcetype === ORDER_RESOURCE_TYPE.COMMERCIAL;
  const isFulfilled = order.status === ORDER_STATUS.FULFILLED;
  const isWaitingPayment = order.status === ORDER_STATUS.WAITING_FOR_PAYMENT;

  const isStatusPaid = status => PAID_STATUSES.includes(status);
  const isPendingFulfilled =
    order.status === ORDER_STATUS.PENDING_FULFILLMENT ||
    isStatusPaid(order.status);
  const dropdownItem = [
    {
      id: DropdownItemId.PRINT,
      icon: <i className="mdi mdi-package-variant mr-2 font-size-18" />,
      label: 'Print Package Slip',
    },
    {
      id: DropdownItemId.EDIT,
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
      href: `/commercial-orders/${order.id}/edit`,
      isHidden: !(isFulfilled && isCommercialOrder) || isPendingFulfilled,
    },
    {
      id: DropdownItemId.SHIP,
      icon: <i className="mdi mdi-send mr-2 font-size-18" />,
      label: 'Ship Order',
      isHidden: !isPendingFulfilled,
    },
    {
      id: DropdownItemId.DOWNLOAD,
      icon: <i className="mdi mdi-cloud-download-outline mr-2 font-size-18" />,
      label: 'Download',
      isHidden: !isCommercialOrder || isPendingFulfilled,
    },
    {
      id: DropdownItemId.COMPLETE,
      label: 'Complete payment',
      isHidden: !isWaitingPayment || isPendingFulfilled,
    },
    {
      id: DropdownItemId.FULFILL,
      label: 'Fulfill Order',
      isHidden: !isPendingFulfilled,
    },
  ];

  const onItemClickHandler = item => {
    const itemHandlers = {
      [DropdownItemId.PRINT]: onPrintPackageSlip,
      [DropdownItemId.COMPLETE]: onComplete,
      [DropdownItemId.DOWNLOAD]: onDownload,
      [DropdownItemId.FULFILL]: onFulfill,
      [DropdownItemId.SHIP]: onShip,
    };

    if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  return (
    <ActionDropdown
      items={dropdownItem}
      onItemClick={onItemClickHandler}
      isDisabled={isDisabled}
      right
    />
  );
};

CommercialActionDropdown.propTypes = {
  order: PropTypes.object.isRequired,
  onPrintPackageSlip: PropTypes.func,
  onComplete: PropTypes.func,
  onDownload: PropTypes.func,
  isDisabled: PropTypes.bool,
  onFulfill: PropTypes.func,
  onShip: PropTypes.func,
};

export default CommercialActionDropdown;
