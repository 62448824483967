import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import InfoSection from 'components/InfoSection/InfoSection';

const MerchandiseHeader = ({ product }) => {
  return (
    <>
      <Row>
        <Col lg="6">
          <InfoSection
            title="Product Details"
            gap="5"
            data={[
              { key: 'Title', value: product?.title ?? '-' },
              { key: 'Brand', value: product?.brand?.title ?? '-' },
              { key: 'Price', value: `$${product?.price ?? '-'}` },
              { key: 'Type', value: product?.resourcetype ?? '-' },
              ...(product?.weight_value
                ? [
                    {
                      key: 'Weight',
                      value: `${product?.weight_value} ${product?.weight_unit}`,
                    },
                  ]
                : []),
            ]}
          />
        </Col>

        <Col lg="6">
          <InfoSection
            title="Additional Information"
            gap="5"
            data={[
              {
                key: 'Categories',
                value:
                  product?.categories?.map(({ title }) => title).join(', ') ??
                  '-',
              },
              { key: 'SKU', value: product?.sku ?? '-' },
              { key: 'Description', value: product?.description ?? '-' },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

MerchandiseHeader.propTypes = {
  product: PropTypes.object.isRequired,
};

export default MerchandiseHeader;
