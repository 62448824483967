import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { prepareAddress } from 'utils/prepareAddress';
import InfoSection from 'components/InfoSection/InfoSection';

import styles from './Header.scss';
import WineclubRow from './WineclubRow';

const Header = ({
  customer,
  onWineClubMemberAction,
  wineClubMemberPending,
  showCancelReasonModal,
  onCancelSubscription,
  onActivateSubscription,
}) => {
  return (
    <>
      <Row>
        <Col lg="6">
          <InfoSection
            title="Customer Details"
            gap="5"
            data={[
              {
                key: 'Customer',
                value:
                  customer?.first_name && customer?.last_name
                    ? `${customer?.first_name} ${customer?.last_name}`
                    : '-',
              },
              { key: 'Email', value: customer?.email ?? '-' },
              { key: 'Birthday', value: customer?.birthday ?? '-' },
              {
                key: 'Last Order Date',
                value: customer?.last_order_date
                  ? format(new Date(customer?.last_order_date), 'yyyy-MM-dd')
                  : '-',
              },
              {
                key: 'Life Time Purchase Value',
                value: customer?.total_sum_of_paid_orders
                  ? `$${customer?.total_sum_of_paid_orders}`
                  : '-',
              },
              {
                key: 'Number Of Shipments',
                value: customer?.total_number_of_shipments ?? '-',
              },
            ]}
          />
        </Col>

        <Col lg="6">
          <InfoSection
            title="Shipping & Billing Info"
            gap="5"
            data={[
              {
                key: 'Shipping Address',
                value: prepareAddress(customer?.customer_profile || {}),
              },
              {
                key: 'Shipping Phone',
                value: customer?.customer_profile?.phone ?? '-',
              },
              {
                key: 'Billing Address',
                value: prepareAddress(customer?.customer_billing_data || {}),
              },
              {
                key: 'Billing Phone',
                value: customer?.customer_billing_data?.phone ?? '-',
              },
            ]}
          />
        </Col>
      </Row>

      {!isEmpty(customer.wine_club_members) && (
        <Row>
          <Col lg="12">
            <Card>
              <CardBody
                className={wineClubMemberPending && styles.loadingContainer}
              >
                <h4 className="card-title">Wine Clubs</h4>
                <Table bordered responsive className={styles.table}>
                  <thead>
                    <tr>
                      <th data-priority="1">ID</th>
                      <th data-priority="1">Brand</th>
                      <th data-priority="1">Member since</th>
                      <th data-priority="1">Subscribed tier</th>
                      <th data-priority="1">Gift</th>
                      <th data-priority="1">Gift message</th>
                      <th data-priority="1">Last order date</th>
                      <th data-priority="1">Cancel date</th>
                      <th data-priority="1">Cancel reason</th>
                      <th data-priority="1">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(customer?.wine_club_members || []).map(item => (
                      <WineclubRow
                        key={item.id}
                        item={item}
                        onWineClubMemberAction={onWineClubMemberAction}
                        showCancelReasonModal={showCancelReasonModal}
                        onCancelSubscription={onCancelSubscription}
                        onActivateSubscription={onActivateSubscription}
                      />
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

Header.propTypes = {
  customer: PropTypes.object,
  showCancelReasonModal: PropTypes.func,
  onWineClubMemberAction: PropTypes.func,
  wineClubMemberPending: PropTypes.bool,
  onCancelSubscription: PropTypes.func,
  onActivateSubscription: PropTypes.func,
};

export default Header;
