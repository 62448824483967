import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';
import DataTable from './DataTable';
import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import { actions as ordersActions } from 'models/orders/slice';
import useSelector from 'hooks/useSelector';
import {
  orderSelector,
  ordersSelector,
  isReportPendingSelector,
  isPendingSelector as ordersIsPendingSelector,
  paginationSelector,
  pendingFulfillmentAllOrdersSelector,
  pendingFulfillmentAllOrdersPaginationSelector,
  orderStatusChangeSelector,
} from 'models/orders/selectors';
import {
  DTC_PENDING_ORDER_TYPES,
  DTC_COMPLETED_ORDER_TYPES,
  DTC_PENDING_ORDER_STATUSES,
  ACTIONS_OPTIONS,
} from './constants';
import { ORDER_PAYMENT_OPTIONS } from 'constants';
import { ORDER_RESOURCE_TYPE } from 'constants/orders';
import Filter from './Filter';
import SelectPaymentMethodPopup from 'components/Popup/SelectPaymentMethodPopup';
import SelectShippingPopup from 'components/Popup/SelectShippingPopup';
import { actions as subscriptionOrdersActions } from 'models/subscriptionOrders/slice';
import OrderMultiActionDropdown from './OrderMultiActionDropdown';
import CommentPopup from 'components/Popup/CommentPopup';

import s from './OrdersSummary.scss';
import { useDataPagination } from 'hooks/useDataPagination';
import useApiRequest from 'hooks/useApiRequest';
import { showErrorMessage, showSuccessMessage } from 'utils/notification';
import { getNormalText } from 'utils/getNormalText';
import { saveAs } from 'file-saver';

const OrdersSummary = ({ title }) => {
  const query = useQuery();

  // Actions
  const fetchOrders = useAction(ordersActions.fetchOrders);
  const fetchPendingFulfillmentAllOrders = useAction(
    ordersActions.fetchPendingFulfillmentAllOrders
  );
  const fetchOrder = useAction(ordersActions.fetchOrder);
  const handleFetchOrderShipmentLabel = useAction(
    ordersActions.fetchOrderShipmentLabel
  );
  const handleFetchOrderShipmentPublicUrl = useAction(
    ordersActions.fetchOrderShipmentPublicUrl
  );
  const sendSubscriptionOrder = useAction(
    subscriptionOrdersActions.sendSubscriptionOrder
  );
  const changeOrderStatus = useAction(ordersActions.changeOrderStatus);
  const completeOrder = useAction(ordersActions.completeOrder);
  const printFile = useAction(ordersActions.printFile);
  const fetchReport = useAction(ordersActions.fetchCommercialReport);

  // Selectors
  const orders = useSelector(ordersSelector);
  const pendingFulfillmentOrders = useSelector(
    pendingFulfillmentAllOrdersSelector
  );
  const orderStatusChange = useSelector(orderStatusChangeSelector);
  const fetchPendingFulfillmentOrderClear = useAction(
    ordersActions.fetchPendingFulfillmentOrderClear
  );
  const ordersIsPending = useSelector(ordersIsPendingSelector);
  const isReportPending = useSelector(isReportPendingSelector);
  const pagination = useSelector(paginationSelector);
  const pendingFulfillmentPagination = useSelector(
    pendingFulfillmentAllOrdersPaginationSelector
  );
  const shipOrderDetails = useSelector(orderSelector);

  const {
    currentPage: fulfillmentPage,
    sortField: fulfillmentSortField,
    sortDirection: fulfillmentSortDirection,
    ...fulfillmentPagination
  } = useDataPagination(pendingFulfillmentPagination, {
    currentPage: query.get('fulfillment_page'),
    sortField: query.get('fulfillment_sort_field'),
    sortDirection: query.get('fulfillment_sort_direction'),
  });

  const {
    currentPage,
    sortField,
    sortDirection,
    ...completedPagination
  } = useDataPagination(pagination, {
    currentPage: query.get('page'),
    sortField: query.get('sort_field'),
    sortDirection: query.get('sort_direction'),
  });
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState({});
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [action, setAction] = useState(ACTIONS_OPTIONS[0]);
  const [showShipOrderModal, setShowShipOrderModal] = useState(false);
  const [isChargeMode, setIsChargeMode] = useState(false);
  const request = useApiRequest();

  // Multi-order-select constants
  const [selectedCompletedOrders, setSelectedCompletedOrders] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [selectedPendingOrders, setSelectedPendingOrders] = useState([]);
  const [pendingOrderIds, setPendingOrderIds] = useState([]);
  const [compltedActionDisabled, setCompltedActionDisabled] = useState(false);
  const [pendingActionDisabled, setPendingActionDisabled] = useState(false);
  const [fulfilledOrderIds, setFulfilledOrderIds] = useState(null);
  const [multiChangeOrderStatus, setMultiChangeOrderStatus] = useState(false);
  const [fulfilledOrderId, setFulfilledOrderId] = useState(null);

  useEffect(() => {
    const selectedResourceTypes = filter.resourcetypes?.length
      ? DTC_COMPLETED_ORDER_TYPES.filter(type =>
          filter.resourcetypes.includes(type.value)
        )
      : DTC_COMPLETED_ORDER_TYPES;
    const excludedStatuses = filter.statuses?.length
      ? DTC_PENDING_ORDER_STATUSES.filter(type =>
          filter.statuses.includes(type.value)
        )
      : DTC_PENDING_ORDER_STATUSES;
    const excludedStatusesToUse = excludedStatuses.length
      ? excludedStatuses
      : DTC_PENDING_ORDER_STATUSES;
    fetchOrders({
      setQuery: true,
      page: currentPage,
      sort_field: sortField,
      sort_direction: sortDirection,
      ...filter,
      excluded_statuses: excludedStatusesToUse.map(type => type.value),
      resourcetypes: selectedResourceTypes.map(type => type.value),
      resourcetypes_labels: selectedResourceTypes.map(type => type.label),
    });
    setSelectedCompletedOrders([]);
    setOrderIds([]);
  }, [
    fetchOrders,
    currentPage,
    sortDirection,
    filter,
    orderStatusChange,
    multiChangeOrderStatus,
  ]);

  useEffect(() => {
    completedPagination.setCurrentPage(1);
    fulfillmentPagination.setCurrentPage(1);
    setSelectedOrders([]);
  }, [filter]);

  useEffect(() => {
    const { paid, ...filterMinusPaid } = filter;
    const selectedResourceTypes = filter.resourcetypes?.length
      ? DTC_PENDING_ORDER_TYPES.filter(type =>
          filter.resourcetypes.includes(type.value)
        )
      : DTC_PENDING_ORDER_TYPES;
    const resourceTypesToUse = selectedResourceTypes.length
      ? selectedResourceTypes
      : DTC_PENDING_ORDER_TYPES;

    const statuses = filter.statuses?.length
      ? DTC_PENDING_ORDER_STATUSES.filter(type =>
          filter.statuses.includes(type.value)
        )
      : DTC_PENDING_ORDER_STATUSES;
    const statusesToUse = statuses.length
      ? statuses
      : DTC_PENDING_ORDER_STATUSES;

    if (!statuses?.length || !selectedResourceTypes?.length) {
      fetchPendingFulfillmentOrderClear();
    } else {
      fetchPendingFulfillmentAllOrders({
        setQuery: true,
        page: fulfillmentPage,
        sort_field: fulfillmentSortField,
        sort_direction: fulfillmentSortDirection,
        ...filterMinusPaid,
        statuses: statusesToUse.map(type => type.value),
        excluded_statuses: [],
        resourcetypes: resourceTypesToUse?.map(type => type.value),
        resourcetypes_labels: resourceTypesToUse.map(type => type.label),
      });
    }
    setSelectedPendingOrders([]);
    setPendingOrderIds([]);
    setMultiChangeOrderStatus(false);
  }, [
    fetchPendingFulfillmentAllOrders,
    fulfillmentPage,
    fulfillmentSortDirection,
    filter,
    orderStatusChange,
    multiChangeOrderStatus,
  ]);

  // Multi-order-select effects for completed and pending.
  useEffect(() => {
    setFulfilledOrderId(null);
    setFulfilledOrderIds(null);
  }, [pendingFulfillmentOrders]);

  useEffect(() => {
    const orderIdsArray = orders.map(order => order.id);
    setOrderIds(orderIdsArray);
  }, [orders]);

  useEffect(() => {
    const orderIdsArray = pendingFulfillmentOrders.map(order => order.id);
    setPendingOrderIds(orderIdsArray);
  }, [pendingFulfillmentOrders]);

  useEffect(() => {
    if (selectedCompletedOrders?.length > 0) {
      setCompltedActionDisabled(true);
    } else {
      setCompltedActionDisabled(false);
    }
  }, [selectedCompletedOrders]);

  useEffect(() => {
    if (selectedPendingOrders?.length > 0) {
      setPendingActionDisabled(true);
    } else {
      setPendingActionDisabled(false);
    }
  }, [selectedPendingOrders]);

  // Order handlers
  const handleDownload = id => {
    fetchReport({ id });
  };

  const handlePrintPackageSlip = id => {
    printFile({ orders_ids: [id], packing_slip: true });
  };

  const openPaymentModal = orderId => {
    fetchOrder(orderId);
    setModal(true);
  };

  const openShipModal = orderId => {
    fetchOrder(orderId);
    setShowShipOrderModal(true);
  };

  const onSendSubscriptionOrder = id => {
    sendSubscriptionOrder(id);
  };

  const onFulfillOrderHandler = (_, { comment }) => {
    const orderToFulfil = pendingFulfillmentOrders.filter(
      order => order.id === fulfilledOrderId
    );
    if (orderToFulfil) {
      if (orderToFulfil[0].resourcetype === ORDER_RESOURCE_TYPE.IMPORTED) {
        completeOrder(fulfilledOrderId);
      } else {
        changeOrderStatus({
          id: fulfilledOrderId,
          status: 'SHIPPED',
          comment,
          orderType: 'Orders',
          skipOrdersUpdate: true,
        });
      }
    } else {
      showErrorMessage(
        'Error!',
        `Unable to find order details for ${fulfilledOrderId} in PendingFulfillment Orders`
      );
    }
    setFulfilledOrderId(null);
  };

  // Multi-Order action handlers.
  const handleSelectAll = useCallback(
    event => {
      if (event.target.checked) {
        setSelectedCompletedOrders(orderIds);
      } else {
        setSelectedCompletedOrders([]);
      }
    },
    [orderIds]
  );

  const handleSelectPendingAll = useCallback(
    event => {
      if (event.target.checked) {
        setSelectedPendingOrders(pendingOrderIds);
      } else {
        setSelectedPendingOrders([]);
      }
    },
    [pendingOrderIds]
  );

  const handleAllPrintPackageSlip = selectedOrder => {
    printFile({ orders_ids: selectedOrder, packing_slip: true });
  };

  const handleAllPendingPrintPackageSlip = () => {
    printFile({ orders_ids: selectedPendingOrders, packing_slip: true });
  };

  const downloadOrderReport = async payload => {
    try {
      return await request({
        url: `/orders/${payload.id}/sales_form`,
        data: payload,
        method: 'get',
        responseType: 'blob',
      });
    } catch (err) {
      Object.entries(err.response?.data ?? {}).forEach(([key, value]) => {
        const preparedKey = getNormalText(key);
        showErrorMessage(
          preparedKey.toString(),
          value.toString() || 'Bad request'
        );
      });
    }
    return {};
  };

  const handleAllDownload = async () => {
    if (selectedCompletedOrders && selectedCompletedOrders.length > 0) {
      try {
        const promises = selectedCompletedOrders.map(orderId =>
          downloadOrderReport({ id: orderId })
        );
        const responses = await Promise.all(promises);
        responses.forEach((response, index) => {
          const orderId = selectedCompletedOrders[index];
          if (response) {
            const blob =
              response instanceof Blob
                ? response
                : new Blob([response.data], { type: 'application/pdf' });
            const filename = `commercial_order_${orderId}.pdf`;
            saveAs(blob, filename);
          } else {
            showErrorMessage(
              'Error!',
              `Unable to download report for order ID: ${orderId}`
            );
          }
        });
      } catch (err) {
        console.error('Error processing downloads:', err);
      }
    }
  };

  const changeAllOrderStatus = payload => {
    return request({
      url: `/orders/${payload.id}/status_changes`,
      data: payload,
      method: 'post',
    });
  };

  const completeAllOrderStatus = payload => {
    return request({
      url: `/orders/${payload.id}/complete`,
      data: payload,
      method: 'post',
    });
  };

  const onFulfillAllOrderHandler = async (_, { comment }) => {
    setFulfilledOrderIds(null);
    if (fulfilledOrderIds && fulfilledOrderIds.length > 0) {
      const ordersMap = new Map(
        pendingFulfillmentOrders.map(order => [order.id, order])
      );
      const results = await Promise.all(
        fulfilledOrderIds.map(async orderId => {
          const orderToFulfill = ordersMap.get(orderId);
          if (!orderToFulfill) {
            return { orderId, status: 'failed', error: 'Order not found' };
          }
          try {
            let response;
            if (orderToFulfill.resourcetype === ORDER_RESOURCE_TYPE.IMPORTED) {
              response = await completeAllOrderStatus({
                id: orderId,
                page: fulfillmentPage,
              });
            } else {
              response = await changeAllOrderStatus({
                id: orderId,
                status: 'SHIPPED',
                comment,
                skipOrdersUpdate: true,
              });
            }
            return { orderId, status: 'success', response };
          } catch (err) {
            const errorDetails = Object.entries(err.response?.data ?? {}).map(
              ([key, value]) => ({
                key: getNormalText(key),
                message: value || 'Bad request',
              })
            );
            return { orderId, status: 'failed', error: errorDetails };
          }
        })
      );
      // Process async results
      results.forEach(result => {
        if (result.status === 'success') {
          showSuccessMessage(
            'Success',
            `Order ${result.orderId} fulfilled successfully!`
          );
        } else {
          result.error.forEach(err =>
            showErrorMessage(err.key.toString(), err.message.toString())
          );
        }
      });
      setFulfilledOrderIds(null);
      setMultiChangeOrderStatus(true);
    } else {
      showErrorMessage('Error', 'No orders to fulfill.');
    }
  };

  const selectedOrdersLength = selectedOrders?.length;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      {fulfilledOrderId != null && (
        <CommentPopup
          title="Fulfill Order"
          onSubmit={onFulfillOrderHandler}
          onClose={() => setFulfilledOrderId(null)}
        />
      )}
      {fulfilledOrderIds != null && (
        <CommentPopup
          title="Fulfill Order"
          onSubmit={onFulfillAllOrderHandler}
          onClose={() => setFulfilledOrderIds(null)}
        />
      )}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filter
                    isPending={ordersIsPending}
                    filter={filter}
                    setFilter={setFilter}
                    action={action}
                    setAction={setAction}
                    withActions={!!selectedOrdersLength}
                  />
                </Col>
              </Row>
              <div className="mb-3">
                <div className={s.subHeading}>Pending Fulfillment</div>
                <Row className="d-flex justify-content-between align-items-center mb-4 mt-1">
                  <div className={s.selectedInformation}>
                    {selectedPendingOrders?.length
                      ? `${selectedPendingOrders?.length} of ${
                          pendingFulfillmentPagination.total_count
                        } orders
                    ${
                      selectedPendingOrders?.length === 1 ? 'is' : 'are'
                    } selected`
                      : 'No selected orders'}
                  </div>
                  {pendingActionDisabled && (
                    <div className={s.actionColumn}>
                      <OrderMultiActionDropdown
                        orders={pendingFulfillmentOrders}
                        orderIds={selectedPendingOrders}
                        onFulfill={() =>
                          setFulfilledOrderIds(selectedPendingOrders)
                        }
                        onPrintPackageSlip={() =>
                          handleAllPendingPrintPackageSlip(
                            pendingFulfillmentOrders
                          )
                        }
                      />
                    </div>
                  )}
                </Row>
                {pendingFulfillmentOrders.length ? (
                  <PaginationTable
                    {...fulfillmentPagination}
                    pagination={pendingFulfillmentPagination}
                  >
                    <DataTable
                      {...fulfillmentPagination}
                      sortField={fulfillmentSortField}
                      sortDirection={fulfillmentSortDirection}
                      isPending={ordersIsPending}
                      orders={pendingFulfillmentOrders}
                      onFulfill={setFulfilledOrderId}
                      paid={filter?.paid}
                      openShipModal={openShipModal}
                      onPrintPackageSlip={handlePrintPackageSlip}
                      orderType={filter?.resourcetypes}
                      isSortable
                      areAllSelected={
                        selectedPendingOrders.length ===
                        pendingFulfillmentOrders?.length
                      }
                      selectedOrders={selectedPendingOrders}
                      updateSelectedOrders={setSelectedPendingOrders}
                      selectAllOrders={handleSelectPendingAll}
                      pending
                      downloadDisabled={pendingActionDisabled}
                    />
                  </PaginationTable>
                ) : (
                  <div className={s.infoText}>All Orders Fulfilled</div>
                )}
              </div>
              <div>
                <div className={s.subHeading}>Completed</div>
                <Row className="d-flex justify-content-between align-items-center mb-4 mt-1">
                  <div className={s.selectedInformation}>
                    {selectedCompletedOrders?.length
                      ? `${selectedCompletedOrders?.length} of ${
                          pagination.total_count
                        } orders
                    ${
                      selectedCompletedOrders?.length === 1 ? 'is' : 'are'
                    } selected`
                      : 'No selected orders'}
                  </div>
                  {compltedActionDisabled && (
                    <div className={s.actionColumn}>
                      <OrderMultiActionDropdown
                        orders={orders}
                        orderIds={selectedCompletedOrders}
                        onPrintPackageSlip={() =>
                          handleAllPrintPackageSlip(selectedCompletedOrders)
                        }
                        onDownload={handleAllDownload}
                      />
                    </div>
                  )}
                </Row>
                <PaginationTable
                  {...completedPagination}
                  pagination={pagination}
                >
                  <DataTable
                    {...completedPagination}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    isPending={ordersIsPending}
                    paid={filter?.paid}
                    openPaymentModal={openPaymentModal}
                    handleFetchOrderShipmentLabel={
                      handleFetchOrderShipmentLabel
                    }
                    handleFetchOrderShipmentPublicUrl={
                      handleFetchOrderShipmentPublicUrl
                    }
                    orders={orders}
                    onDownload={handleDownload}
                    onPrintPackageSlip={handlePrintPackageSlip}
                    downloadDisabled={isReportPending || compltedActionDisabled}
                    sendSubscriptionOrder={sendSubscriptionOrder}
                    onSendSubscriptionOrder={onSendSubscriptionOrder}
                    orderType={filter?.resourcetypes}
                    isSortable
                    areAllSelected={
                      selectedCompletedOrders.length === orders?.length
                    }
                    selectedOrders={selectedCompletedOrders}
                    updateSelectedOrders={setSelectedCompletedOrders}
                    selectAllOrders={handleSelectAll}
                    completed
                  />
                  {modal && (
                    <SelectPaymentMethodPopup
                      isOpen={modal}
                      setIsOpen={setModal}
                      options={ORDER_PAYMENT_OPTIONS}
                    />
                  )}

                  {showShipOrderModal && (
                    <SelectShippingPopup
                      isOpen={showShipOrderModal}
                      setIsOpen={setShowShipOrderModal}
                      withFooter={false}
                      withTotal={false}
                      setIsChargeMode={setIsChargeMode}
                      isChargeMode={isChargeMode}
                      isOnlyShipping
                      order={shipOrderDetails}
                    />
                  )}
                </PaginationTable>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

OrdersSummary.propTypes = {
  title: PropTypes.string,
};

export default OrdersSummary;
