import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { Row, Col } from 'reactstrap';
import useSelector from 'hooks/useSelector';
import PageWrapper from 'components/PageWrapper';
import { actions as organizationsActions } from 'models/organizations/slice';
import { actions as customersActions } from 'models/customers/slice';
import { isOnlySingleRole } from 'utils/isOnlySingleRole';
import { PERMISSION_TYPES } from 'constants/permissions';

import {
  organizationSelector,
  isPendingSelector,
} from 'models/organizations/selectors';
import {
  paymentMethodsSelector,
  paymentTypesSelector,
} from 'models/customers/selectors';
import {
  rolesSelector,
  hasOwnerOrManagerRoleSelector,
} from 'models/user/selectors';
import Preloader from 'components/Preloader';
import Breadcrumbs from 'components/Breadcrumbs';
import isEmpty from 'lodash/isEmpty';
import PaymentMethodsTable from 'components/PaymentMethodsTable';
import PaymentTypesTable from 'components/PaymentTypesTable';
import { getNormalText } from 'utils/getNormalText';
import RequestPaymentTable from 'components/RequestPaymentTable';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import InfoSection from 'components/InfoSection/InfoSection';

const OrganizationsShow = () => {
  // Params
  const { id } = useParams();

  // Actions
  const fetchOrganization = useAction(organizationsActions.fetchOrganization);
  const fetchPaymentMethods = useAction(customersActions.fetchPaymentMethods);
  const fetchPaymentTypes = useAction(customersActions.fetchPaymentTypes);
  const removePaymentMethod = useAction(customersActions.removePaymentMethod);

  // Selectors
  const organization = useSelector(organizationSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const paymentTypes = useSelector(paymentTypesSelector);
  const pending = useSelector(isPendingSelector);
  const roles = useSelector(rolesSelector);

  // Constants
  const isSalesRep = isOnlySingleRole(roles, PERMISSION_TYPES.SALES_REP);
  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);
  const isEditLinkVisible = isSalesRep || isOwnerOrManagerRole;

  // Effects
  useEffect(() => {
    fetchOrganization(id);
  }, [fetchOrganization, id]);

  useEffect(() => {
    if (organization?.contact_person?.id) {
      fetchPaymentMethods({
        id: organization?.contact_person?.id,
        organization_id: organization.id,
      });
      fetchPaymentTypes(organization?.contact_person?.id);
    }
  }, [organization, fetchPaymentMethods, fetchPaymentTypes]);

  // Handlers
  const onRemovePaymentMethod = methodId => {
    removePaymentMethod({
      id: methodId,
      customerId: organization?.contact_person?.id,
    });
  };

  if (pending) return <Preloader />;

  return (
    <PageWrapper>
      {isEditLinkVisible ? (
        <Breadcrumbs
          link={`/commercial-customers/${organization?.id}/edit`}
          title={`Edit ${organization?.title} #${organization?.id}`}
          breadcrumbItems={[
            {
              title: 'Back to commercial customers',
              link: '/commercial-customers/',
              withSearch: true,
            },
          ]}
        />
      ) : null}
      {!isEmpty(organization) && (
        <>
          <Row>
            <Col lg="6">
              <InfoSection
                title="Organization Details"
                gap="5"
                data={[
                  { key: 'Title', value: organization.title },
                  { key: 'License Number', value: organization.license_number },
                  { key: 'Company Type', value: organization.company_type },
                  {
                    key: 'Price Type',
                    value: getNormalText(organization.price_type),
                  },
                  ...(organization?.default_warehouse
                    ? [
                        {
                          key: 'Default Warehouse',
                          value: organization.default_warehouse.title,
                        },
                      ]
                    : []),
                ]}
              />
            </Col>

            <Col lg="6">
              <InfoSection
                title="Payment Contact Information"
                gap="5"
                data={[
                  {
                    key: 'Name',
                    value: `${organization.contact_person.first_name} ${organization.contact_person.last_name}`,
                  },
                  { key: 'Email', value: organization.contact_person.email },
                  {
                    key: 'Phone',
                    value: formatPhoneNumber(organization.contact_person.phone),
                  },
                  {
                    key: 'Address',
                    value: Object.values(organization?.address).join(' '),
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col md={8}>
          <RequestPaymentTable
            organizationId={organization?.id}
            paymentMethods={paymentMethods}
            contactEmail={organization?.contact_person?.email}
            title="Request Payment Information"
          />
        </Col>
        <Col lg="12">
          {paymentMethods?.length !== 0 && paymentTypes?.length !== 0 && (
            <>
              <PaymentTypesTable paymentTypes={paymentTypes} />
              <PaymentMethodsTable
                paymentMethods={paymentMethods}
                onRemovePaymentMethod={onRemovePaymentMethod}
              />
            </>
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default OrganizationsShow;
