/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import cx from 'classnames';
import { format, isValid } from 'date-fns';
import formatPrice from 'utils/formatPrice';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CustomInput } from 'reactstrap';
import { HUMAN_ORDER_STATUSES, ORDER_TYPES_ITEMS } from 'constants';

import SortArrows from 'components/SortArrows';
import handleSort from 'utils/handleSort';

import s from './DataTable.scss';
import { getNormalText } from 'utils/getNormalText';
import { SHIPMENT_STATUS_LABELS } from 'constants/shipment';
import OrderActionDropdown from './OrderActionDropdown';

const DataTable = ({
  isPending,
  orders,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
  onDownload,
  onPrintPackageSlip,
  downloadDisabled,
  paid,
  onSendSubscriptionOrder,
  orderType,
  onFulfill,
  openShipModal,
  selectAllOrders,
  areAllSelected,
  selectedOrders,
  updateSelectedOrders,
  pending,
  completed,
}) => {
  const dateColumn = String(paid) === 'true' ? 'paid_at' : 'updated_at';

  const isSelected = item => {
    return selectedOrders?.indexOf(item.id) > -1;
  };

  const handleSelectOrder = useCallback(
    (itemId, event) => {
      if (event.target.checked) {
        updateSelectedOrders([...selectedOrders, itemId]);
      } else {
        updateSelectedOrders(selectedOrders.filter(i => i !== itemId));
      }
    },
    [selectedOrders]
  );

  return (
    <>
      <thead>
        <tr>
          {pending && (
            <th>
              <div className={cx(s.checkbox, 'custom-control custom-checkbox')}>
                <CustomInput
                  type="checkbox"
                  id="confirm"
                  name="confirm"
                  onChange={e => selectAllOrders(e)}
                  checked={areAllSelected}
                />
              </div>
            </th>
          )}
          {completed && (
            <th>
              <div className={cx(s.checkbox, 'custom-control custom-checkbox')}>
                <CustomInput
                  type="checkbox"
                  id="confirmPending"
                  name="confirm"
                  onChange={e => selectAllOrders(e)}
                  checked={areAllSelected}
                />
              </div>
            </th>
          )}
          <th data-priority="1" className="text-nowrap">
            Invoice #
          </th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort(
                dateColumn,
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            {getNormalText(dateColumn)}
            <SortArrows
              isActive={sortField === dateColumn}
              sortDirection={sortDirection}
            />
          </th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort(
                'total_price',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            Total Price
            <SortArrows
              isActive={sortField === 'total_price'}
              sortDirection={sortDirection}
            />
          </th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort(
                'tips_amount',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            Tip
            <SortArrows
              isActive={sortField === 'tips_amount'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="3">Brand</th>
          <th data-priority="3">Customer</th>
          <th data-priority="3">Status</th>
          <th data-priority="3">Shipment Status</th>
          <th data-priority="3">Type</th>
          <th data-priority="3">Wine Associate</th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {orders?.map(item => {
          const orderLink = {
            pathname: `/orders/${item.id}`,
            state: {
              previousPath: '/orders/',
            },
          };
          return (
            <tr key={item.id}>
              {pending && (
                <td>
                  <div
                    className={cx(s.checkbox, 'custom-control custom-checkbox')}
                  >
                    <CustomInput
                      type="checkbox"
                      onChange={event => handleSelectOrder(item.id, event)}
                      checked={isSelected(item)}
                      id={item.id}
                      name={item.id}
                    />
                  </div>
                </td>
              )}
              {completed && (
                <td>
                  <div
                    className={cx(s.checkbox, 'custom-control custom-checkbox')}
                  >
                    <CustomInput
                      type="checkbox"
                      onChange={event => handleSelectOrder(item.id, event)}
                      checked={isSelected(item)}
                      id={item.id}
                      name={item.id}
                    />
                  </div>
                </td>
              )}
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item.invoice_number}
                </Link>
              </td>
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item?.[dateColumn] && isValid(Date.parse(item?.[dateColumn]))
                    ? format(
                        Date.parse(item?.[dateColumn]),
                        'yyyy-MM-dd, h:mm aaa'
                      )
                    : ''}
                </Link>
              </td>
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {formatPrice(item.total_price)} {item.total_price_currency}
                </Link>
              </td>
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {formatPrice(item.tips_amount)} {item.tips_amount_currency}
                </Link>
              </td>
              <td>
                {' '}
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item.brand?.title}
                </Link>{' '}
              </td>
              <td>
                {' '}
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item.resourcetype === 'ContainerWorldOrder' ? (
                    <>
                      CW Order id: {item.customer?.container_world_order_id}
                      <br />
                      Customer id: {item.customer?.customer_number}
                    </>
                  ) : item.resourcetype === 'CommercialOrder' ? (
                    <> {item.organization?.title}</>
                  ) : item.customer ? (
                    <>
                      {' '}
                      {item.customer?.first_name} {item.customer?.last_name}{' '}
                      <br />({item.customer?.email})
                    </>
                  ) : (
                    '-'
                  )}{' '}
                </Link>
              </td>
              <td>
                {' '}
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {HUMAN_ORDER_STATUSES[item.status]}{' '}
                </Link>
              </td>
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {SHIPMENT_STATUS_LABELS[item?.shipment_status] ?? '-'}
                </Link>
              </td>
              <td>
                {' '}
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {ORDER_TYPES_ITEMS[item.resourcetype]}
                </Link>
              </td>
              <td>
                {' '}
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {`${item.host_user?.first_name || ''} ${item.host_user
                    ?.last_name || ''}`}
                </Link>
              </td>
              <td className={s.actionContainer}>
                <OrderActionDropdown
                  order={item}
                  isSubscriptionOrder={orderType?.[0] === 'Subscription Order'}
                  onDownload={() => onDownload?.(item.id)}
                  onSendOrder={() => onSendSubscriptionOrder?.(item.id)}
                  onFulfill={() => onFulfill?.(item.id)}
                  onPrintPackageSlip={() => onPrintPackageSlip?.(item.id)}
                  isDisabled={!!downloadDisabled}
                  onShip={() => openShipModal?.(item.id)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  orders: PropTypes.array,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
  onDownload: PropTypes.func,
  onPrintPackageSlip: PropTypes.func,
  downloadDisabled: PropTypes.bool,
  paid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onSendSubscriptionOrder: PropTypes.func,
  orderType: PropTypes.array,
  onFulfill: PropTypes.func,
  openShipModal: PropTypes.func,
  updateSelectedOrders: PropTypes.func,
  selectAllOrders: PropTypes.func,
  areAllSelected: PropTypes.bool,
  pending: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  completed: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  selectedOrders: PropTypes.array,
};

export default DataTable;
