import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { isEmpty } from 'lodash';
import InfoSection from 'components/InfoSection/InfoSection';

const FoodHeader = ({ product }) => {
  return (
    <>
      <Row>
        <Col lg="6">
          <InfoSection
            title="Food Product Details"
            gap="5"
            data={[
              { key: 'Title', value: product?.title ?? '-' },
              { key: 'Brand', value: product?.brand?.title ?? '-' },
              { key: 'Is Addition?', value: product?.addition ? 'Yes' : 'No' },
              {
                key: 'Price',
                value: `${product?.price ?? '-'} ${product?.price_currency ??
                  ''}`,
              },
              {
                key: 'Categories',
                value:
                  product?.categories?.map(item => item?.title).join(', ') ??
                  '-',
              },
              ...(product?.weight_value
                ? [
                    {
                      key: 'Weight',
                      value: `${product?.weight_value} ${product?.weight_unit}`,
                    },
                  ]
                : []),
              { key: 'Calorific', value: product?.calorific ?? '-' },
              { key: 'Type', value: product?.resourcetype ?? '-' },
              { key: 'SKU', value: product?.sku ?? '-' },
            ]}
          />
        </Col>

        {!isEmpty(product?.food_additions) && (
          <Col lg="6">
            <InfoSection
              title="Food Additions"
              gap="5"
              data={[
                {
                  key: 'Food Additions',
                  value:
                    product?.food_additions?.map(el => el.title).join(', ') ??
                    '-',
                },
              ]}
            />
          </Col>
        )}
        {!isEmpty(product?.wine_pairings) && (
          <Col lg="6">
            <InfoSection
              title="Wine Pairings"
              gap="5"
              data={[
                {
                  key: 'Wine Pairings',
                  value:
                    product?.wine_pairings?.map(el => el.title).join(', ') ??
                    '-',
                },
              ]}
            />
          </Col>
        )}

        <Col lg="6">
          <InfoSection
            title="Description"
            gap="5"
            data={[{ key: 'Description', value: product?.description ?? '-' }]}
          />
        </Col>
      </Row>
    </>
  );
};

FoodHeader.propTypes = {
  product: PropTypes.object,
};

export default FoodHeader;
