import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Table, Input } from 'reactstrap';

import { TRANSFERS_TYPES, W_TO_W_STATUSES } from 'constants';
import { getProductTitleWithVintage } from 'utils/preparedProductTitle';
import InfoSection from 'components/InfoSection/InfoSection';
import classNames from 'classnames';
import styles from './WarehouseTransfer.scss';
import useSelector from 'hooks/useSelector';
import { hasOwnerOrManagerRoleSelector } from 'models/user/selectors';
import SelectField from 'components/SelectField/SelectField';

const WarehouseTransfer = ({
  transfer,
  transferStatus,
  quantity,
  setQuantity,
  setIsInvalid,
  exciseReason,
  setExciseReason,
}) => {
  const hasOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);
  const isAdjustment = typeof transfer?.parent_transfer_id === 'number';
  const [senderNeedsExciseReason, setSenderNeedsExciseReason] = useState(false);
  const senderIsExcise = transfer?.sender?.is_used_as_excise_warehouse;
  const receiverIsExcise = transfer?.receiver?.is_used_as_excise_warehouse;

  const Subhead = () => (
    <div className={styles.subhead}>
      <div className={styles.subheadCol}>START</div>
      <div className={styles.subheadCol}>CLOSE</div>
    </div>
  );

  const comments = transfer?.comment || '';

  const showExciseDropdown = () => {
    return senderNeedsExciseReason && receiverIsExcise !== senderIsExcise;
  };

  const getExciseReasons = () => {
    if (senderIsExcise && !receiverIsExcise) {
      return [
        {
          label: 'Returned from Duty Paid Sources',
          value: 'RETURNED_FROM_DUTY_PAID_SOURCES',
        },
        {
          label: 'Returned from Duty Free Sources',
          value: 'RETURNED_FROM_NON_DUTY_PAID_SOURCES',
        },
      ];
    } else if (!senderIsExcise && receiverIsExcise) {
      return [
        {
          label: 'Wine removed for other purposes duty',
          value: 'WINE_REMOVED_FOR_OTHER_PURPOSES_DUTY',
        },
        {
          label: 'Return to Wine Licensee',
          value: 'RETURN_TO_WINE_LICENSEE',
        },
      ];
    }
    return [];
  };

  return (
    <>
      <Row>
        <Col lg="6">
          <InfoSection
            title="Transfer Details"
            data={[
              { key: 'Operator', value: transfer?.operator?.email ?? '-' },
              {
                key: 'Type',
                value: TRANSFERS_TYPES[transfer?.resourcetype] ?? '-',
              },
              {
                key: 'Comments',
                value:
                  comments
                    .split('\n')
                    .map((item, id) => <p key={id}>{item}</p>) || '-',
              },
              {
                key: 'Status',
                value: W_TO_W_STATUSES[transfer?.status] ?? '-',
              },
              ...(isAdjustment
                ? [
                    {
                      key: 'Parent Transfer',
                      value: transfer?.parent_transfer_id ? (
                        <Link
                          to={`/transfers/${transfer?.parent_transfer_id}/show`}
                        >
                          {transfer?.parent_transfer_id}
                        </Link>
                      ) : (
                        '-'
                      ),
                    },
                  ]
                : []),
              {
                key: 'Sender',
                value: transfer?.sender?.title ? (
                  <Link to={`/warehouses/${transfer?.sender?.id}/show`}>
                    {transfer?.sender?.title}
                  </Link>
                ) : (
                  '-'
                ),
              },
              {
                key: 'Receiver',
                value: transfer?.receiver?.title ? (
                  <Link to={`/warehouses/${transfer?.receiver?.id}/show`}>
                    {transfer?.receiver?.title}
                  </Link>
                ) : (
                  '-'
                ),
              },
            ]}
          />
        </Col>
      </Row>
      {transferStatus === 'DISPUTED' && showExciseDropdown() && (
        <Row>
          <Col md="3">
            <SelectField
              className="mb-2"
              name="excise_reason"
              defaultValue={exciseReason}
              onChange={option => {
                setExciseReason(option.value);
              }}
              label="Excise Reason*"
              options={getExciseReasons()}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col lg="12">
          <Card className="p-3">
            <h4 className="card-title mb-4">Transfer Items</h4>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table id="tech-companies-1" bordered responsive>
                  <thead>
                    <tr>
                      <th data-priority="1">SKU</th>
                      <th data-priority="1">Title</th>
                      <th
                        className={
                          transferStatus === 'DISPUTED' &&
                          classNames(styles.head, styles.headWithSubhead)
                        }
                        data-priority={
                          transferStatus === 'DISPUTED' ? '2' : '1'
                        }
                      >
                        Sender Inventory
                        {transferStatus === 'DISPUTED' && <Subhead />}
                      </th>
                      {transferStatus === 'DISPUTED' ? (
                        <>
                          <th data-priority="1">Original Quantity</th>
                          {hasOwnerOrManagerRole && (
                            <th data-priority="1">Actual Quantity</th>
                          )}
                        </>
                      ) : (
                        <th data-priority="1">Quantity</th>
                      )}
                      <th
                        className={
                          transferStatus === 'DISPUTED' &&
                          classNames(styles.head, styles.headWithSubhead)
                        }
                        data-priority={
                          transferStatus === 'DISPUTED' ? '2' : '1'
                        }
                      >
                        Receiver Inventory
                        {transferStatus === 'DISPUTED' && <Subhead />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transfer?.transfer_items?.map(item => {
                      const senderStart =
                        item?.product?.inventories?.find(
                          inventory =>
                            inventory?.warehouse_title ===
                            transfer?.sender?.title
                        )?.product_count + item.quantity;

                      const senderClose =
                        quantity[item.product.id] >= 0
                          ? senderStart - quantity[item.product.id]
                          : senderStart - item.quantity;

                      const receiverStart =
                        item?.product?.inventories?.find(
                          inventory =>
                            inventory?.warehouse_title ===
                            transfer?.receiver?.title
                        )?.product_count - item.quantity;

                      const receiverClose =
                        quantity[item.product.id] >= 0
                          ? receiverStart + quantity[item.product.id]
                          : receiverStart + item.quantity;

                      return (
                        <tr key={item.product.id}>
                          <td>{item?.product?.sku}</td>
                          <td>
                            <Link
                              to={`/products/${item.product?.id}/show`}
                              className="text-dark font-weight-bold"
                            >
                              {getProductTitleWithVintage(
                                item?.title,
                                item?.product?.vintage
                              )}
                            </Link>
                          </td>
                          <td>
                            <div className={styles.subContentContainer}>
                              <p className={styles.subContent}>{senderStart}</p>
                              {transferStatus === 'DISPUTED' && (
                                <p className={styles.subContent}>
                                  {senderClose}
                                </p>
                              )}
                            </div>
                          </td>
                          <td>{item.quantity} </td>
                          {transferStatus === 'DISPUTED' &&
                            hasOwnerOrManagerRole && (
                              <td>
                                <Input
                                  type="number"
                                  invalid={
                                    quantity[item.product.id] > senderStart ||
                                    quantity[item.product.id] < -1
                                  }
                                  className="form-control"
                                  id={`quantity-${item.product.id}`}
                                  name={`quantity-${item.product.id}`}
                                  max={senderStart}
                                  min={-1}
                                  value={quantity[item.product.id]}
                                  onChange={event => {
                                    const newQuantity =
                                      event.target.value !== ''
                                        ? Number(event.target.value)
                                        : null;
                                    setQuantity(prevQuantity => ({
                                      ...prevQuantity,
                                      [item.product.id]: newQuantity,
                                    }));
                                    setIsInvalid(prev => ({
                                      ...prev,
                                      [item.product.id]:
                                        newQuantity > senderStart ||
                                        newQuantity < -1,
                                    }));
                                  }}
                                  onBlur={event => {
                                    const newQuantity =
                                      event.target.value !== ''
                                        ? Number(event.target.value)
                                        : null;
                                    if (newQuantity < item.quantity) {
                                      setSenderNeedsExciseReason(true);
                                    } else if (newQuantity >= item.quantity) {
                                      setSenderNeedsExciseReason(false);
                                    }
                                  }}
                                />
                              </td>
                            )}
                          <td>
                            <div className={styles.subContentContainer}>
                              <p className={styles.subContent}>
                                {receiverStart}
                              </p>
                              {transferStatus === 'DISPUTED' && (
                                <p className={styles.subContent}>
                                  {receiverClose}
                                </p>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

WarehouseTransfer.propTypes = {
  transfer: PropTypes.object,
  transferStatus: PropTypes.string,
  quantity: PropTypes.object,
  setQuantity: PropTypes.func,
  setIsInvalid: PropTypes.func,
  exciseReason: PropTypes.object,
  setExciseReason: PropTypes.func,
};

export default WarehouseTransfer;
