import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';

import Filters from 'components/Filters';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';

import DataTable from './DataTable';

import useQuery from 'hooks/useQuery';
import { getProductsQuery } from 'utils/getProductsQuery';

import useAction from 'hooks/useAction';
import { actions as ordersActions } from 'models/orders/slice';
import useSelector from 'hooks/useSelector';
import { format } from 'date-fns';
import {
  orderSelector,
  ordersSelector,
  isReportPendingSelector,
  isPendingSelector as ordersIsPendingSelector,
  pendingFulfillmentCommercialOrdersSelector,
  paginationSelector,
  pendingFulfillmentCommercialOrdersPaginationSelector,
  orderStatusChangeSelector,
} from 'models/orders/selectors';
import { rolesSelector } from 'models/user/selectors';
import { isPendingSelector as brandsIsPendingSelector } from 'models/brands/selectors';
import CommercialMultiActionDropdown from './CommercialMultiActionDropdown';
import useApiRequest from 'hooks/useApiRequest';
import { showErrorMessage, showSuccessMessage } from 'utils/notification';
import { getNormalText } from 'utils/getNormalText';
import { saveAs } from 'file-saver';

import {
  ORDER_PAYMENT_OPTIONS,
  UNPAID_STATUSES,
  PAID_STATUSES,
} from 'constants';
import SelectPaymentMethodPopup from 'components/Popup/SelectPaymentMethodPopup';
import SelectShippingPopup from 'components/Popup/SelectShippingPopup';
import { COMMERCIAL_ORDER_DISPLAY_STATUS } from './constants';
import { PERMISSION_TYPES } from 'constants/permissions';
import CommentPopup from 'components/Popup/CommentPopup';
import { useDataPagination } from 'hooks/useDataPagination';
import styles from './CommercialOrdersSummary.scss';

const ORDER_TYPES = [
  { apiValue: 'CommercialOrder', userValue: 'Commercial Order' },
  { apiValue: 'ContainerWorldOrder', userValue: 'ContainerWorld Order' },
];

const CommercialOrdersSummary = ({ title }) => {
  const fetchCommercialOrders = useAction(ordersActions.fetchCommercialOrders);
  const fetchPendingFulfillmentCommercialOrders = useAction(
    ordersActions.fetchPendingFulfillmentCommercialOrders
  );
  const changeOrderStatus = useAction(ordersActions.changeOrderStatus);
  const brandsIsPending = useSelector(brandsIsPendingSelector);
  const handleFetchOrderShipmentLabel = useAction(
    ordersActions.fetchOrderShipmentLabel
  );
  const handleFetchOrderShipmentPublicUrl = useAction(
    ordersActions.fetchOrderShipmentPublicUrl
  );
  const orders = useSelector(ordersSelector);
  const pendingFulfillmentOrders = useSelector(
    pendingFulfillmentCommercialOrdersSelector
  );
  const orderStatusChange = useSelector(orderStatusChangeSelector);
  const fetchReport = useAction(ordersActions.fetchCommercialReport);
  const printPackingSlip = useAction(ordersActions.printFile);
  const fetchOrder = useAction(ordersActions.fetchOrder);
  const ordersIsPending = useSelector(ordersIsPendingSelector);
  const isReportPending = useSelector(isReportPendingSelector);
  const pagination = useSelector(paginationSelector);
  const pendingFulfillmentPagination = useSelector(
    pendingFulfillmentCommercialOrdersPaginationSelector
  );
  const roles = useSelector(rolesSelector);

  const query = useQuery();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const shipOrderDetails = useSelector(orderSelector);
  const request = useApiRequest();

  const {
    currentPage: fulfillmentPage,
    sortField: fulfillmentSortField,
    sortDirection: fulfillmentSortDirection,
    ...fulfillmentPagination
  } = useDataPagination(pendingFulfillmentPagination, {
    currentPage: query.get('page'),
    sortField: query.get('sort_field'),
    sortDirection: query.get('sort_direction'),
  });

  const {
    currentPage,
    sortField,
    sortDirection,
    ...completedPagination
  } = useDataPagination(pagination, {
    currentPage: query.get('page'),
    sortField: query.get('sort_field'),
    sortDirection: query.get('sort_direction'),
  });
  const [dateRange, setDateRange] = useState({});

  const [modal, setModal] = useState(false);
  const [fulfilledOrderId, setFulfilledOrderId] = useState(null);
  const [showShipOrderModal, setShowShipOrderModal] = useState(false);
  const [isChargeMode, setIsChargeMode] = useState(false);
  const isPending = ordersIsPending || brandsIsPending;
  const isSalesRep =
    Array.isArray(roles) &&
    roles.length === 1 &&
    roles.includes(PERMISSION_TYPES.SALES_REP);

  // Multi-order-select constants
  const [selectedCompletedOrders, setSelectedCompletedOrders] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [selectedPendingOrders, setSelectedPendingOrders] = useState([]);
  const [pendingOrderIds, setPendingOrderIds] = useState([]);
  const [compltedActionDisabled, setCompltedActionDisabled] = useState(false);
  const [pendingActionDisabled, setPendingActionDisabled] = useState(false);
  const [fulfilledOrderIds, setFulfilledOrderIds] = useState(null);
  const [multiChangeOrderStatus, setMultiChangeOrderStatus] = useState(false);

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    if (result.product_ids && result.product_labels) {
      result.products = getProductsQuery(
        String(result.product_ids),
        String(result.product_labels)
      );
    }
    if (!filters?.per_page) {
      filters.per_page = 10;
    }
    setFilters({
      ...filters,
      ...result,
      excluded_statuses: [
        ...UNPAID_STATUSES,
        PAID_STATUSES,
        'PENDING_FULFILLMENT',
      ],
    });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchCommercialOrders({
        ...filters,
        page: currentPage,
        sort_field: sortField,
        sort_direction: sortDirection,
        date_range_start: dateRange.startDate,
        date_range_end: dateRange.endDate,
        resourcetypes: filters.resourcetypes
          ? [filters.resourcetypes]
          : ['CommercialOrder', 'ContainerWorldOrder'],
        setQuery: true,
        order_contains: filters.order_contains,
      });
    }
    setSelectedCompletedOrders([]);
    setOrderIds([]);
  }, [
    fetchCommercialOrders,
    currentPage,
    filters,
    sortDirection,
    sortField,
    dateRange,
    isSalesRep,
    orderStatusChange,
    multiChangeOrderStatus,
  ]);

  useEffect(() => {
    completedPagination.setCurrentPage(1);
    fulfillmentPagination.setCurrentPage(1);
  }, [filters, sortDirection, sortField, dateRange]);

  useEffect(() => {
    fulfillmentPagination.setCurrentPage(1);
  }, [filters, fulfillmentSortDirection, fulfillmentSortField]);

  useEffect(() => {
    fetchPendingFulfillmentCommercialOrders({
      ...filters,
      page: fulfillmentPage,
      sort_field: fulfillmentSortField,
      sort_direction: fulfillmentSortDirection,
      excluded_statuses: [],
      resourcetypes: filters.resourcetypes
        ? [filters.resourcetypes]
        : ['CommercialOrder', 'ContainerWorldOrder'],
      setQuery: true,
      order_contains: filters.order_contains,
    });
    setSelectedPendingOrders([]);
    setPendingOrderIds([]);
    setMultiChangeOrderStatus(false);
  }, [
    fetchPendingFulfillmentCommercialOrders,
    fulfillmentPage,
    filters,
    fulfillmentSortDirection,
    fulfillmentSortField,
    orderStatusChange,
    multiChangeOrderStatus,
  ]);

  // Multi-order-select effects for completed and pending.
  useEffect(() => {
    setFulfilledOrderId(null);
    setFulfilledOrderIds(null);
  }, [pendingFulfillmentOrders]);

  useEffect(() => {
    const orderIdsArray = orders.map(order => order.id);
    setOrderIds(orderIdsArray);
  }, [orders]);

  useEffect(() => {
    const orderIdsArray = pendingFulfillmentOrders.map(order => order.id);
    setPendingOrderIds(orderIdsArray);
  }, [pendingFulfillmentOrders]);

  useEffect(() => {
    if (selectedCompletedOrders?.length > 0) {
      setCompltedActionDisabled(true);
    } else {
      setCompltedActionDisabled(false);
    }
  }, [selectedCompletedOrders]);

  useEffect(() => {
    if (selectedPendingOrders?.length > 0) {
      setPendingActionDisabled(true);
    } else {
      setPendingActionDisabled(false);
    }
  }, [selectedPendingOrders]);

  const onFulfillOrderHandler = (_, { comment }) => {
    if (fulfilledOrderId) {
      changeOrderStatus({
        id: fulfilledOrderId,
        status: 'SHIPPED',
        comment,
        orderType: 'CommercialOrder',
        skipOrdersUpdate: true,
      });
      setFulfilledOrderId(null);
    }
  };

  const handlePrintPackageSlip = id => {
    printPackingSlip({ orders_ids: [id], packing_slip: true });
  };
  // TODO: move functions to utils

  const getStartDate = date => {
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const getEndDate = date => {
    date.setHours(23, 59, 59, 999);
    return date;
  };

  const handleDateChange = value => {
    setDateRange({
      startDate: format(
        getStartDate(value.startDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      endDate: format(
        getEndDate(value.endDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
    });
  };

  const openPaymentModal = orderId => {
    fetchOrder(orderId);
    setModal(true);
  };

  const openShipModal = orderId => {
    fetchOrder(orderId);
    setShowShipOrderModal(true);
  };

  const handleDownload = id => {
    fetchReport({ id });
  };

  // Multi order select action handlers.
  const handleSelectAll = useCallback(
    event => {
      if (event.target.checked) {
        setSelectedCompletedOrders(orderIds);
      } else {
        setSelectedCompletedOrders([]);
      }
    },
    [orderIds]
  );

  const handleSelectPendingAll = useCallback(
    event => {
      if (event.target.checked) {
        setSelectedPendingOrders(pendingOrderIds);
      } else {
        setSelectedPendingOrders([]);
      }
    },
    [pendingOrderIds]
  );

  const handleAllPrintPackageSlip = selectedOrders => {
    printPackingSlip({
      orders_ids: selectedOrders,
      packing_slip: true,
    });
  };

  const downloadOrderReport = async payload => {
    try {
      return await request({
        url: `/commercial_orders/${payload.id}/sales_form`,
        data: payload,
        method: 'get',
        responseType: 'blob',
      });
    } catch (err) {
      Object.entries(err.response?.data ?? {}).forEach(([key, value]) => {
        const preparedKey = getNormalText(key);
        showErrorMessage(
          preparedKey.toString(),
          value.toString() || 'Bad request'
        );
      });
    }
    return {};
  };

  const handleAllDownload = async () => {
    if (selectedCompletedOrders && selectedCompletedOrders.length > 0) {
      try {
        const promises = selectedCompletedOrders.map(orderId =>
          downloadOrderReport({ id: orderId })
        );
        const responses = await Promise.all(promises);
        responses.forEach((response, index) => {
          const orderId = selectedCompletedOrders[index];
          if (response) {
            const blob =
              response instanceof Blob
                ? response
                : new Blob([response.data], { type: 'application/pdf' });
            const filename = `commercial_order_${orderId}.pdf`;
            saveAs(blob, filename);
          } else {
            showErrorMessage(
              'Error!',
              `Unable to download report for order ID: ${orderId}`
            );
          }
        });
      } catch (err) {
        console.error('Error processing downloads:', err);
      }
    }
  };

  const changeAllOrderStatus = payload => {
    return request({
      url: `/orders/${payload.id}/status_changes`,
      data: payload,
      method: 'post',
    });
  };

  const onFulfillAllOrderHandler = async (_, { comment }) => {
    setFulfilledOrderIds(null);
    if (fulfilledOrderIds && fulfilledOrderIds.length > 0) {
      const results = await Promise.all(
        fulfilledOrderIds.map(async orderId => {
          try {
            const response = await changeAllOrderStatus({
              id: orderId,
              status: 'SHIPPED',
              comment,
              skipOrdersUpdate: true,
              orderType: 'CommercialOrder',
            });
            return { orderId, status: 'success', response };
          } catch (err) {
            const errorDetails = Object.entries(err.response?.data ?? {}).map(
              ([key, value]) => ({
                key: getNormalText(key),
                message: value || 'Bad request',
              })
            );
            return { orderId, status: 'failed', error: errorDetails };
          }
        })
      );
      // Process async results
      results.forEach(result => {
        if (result.status === 'success') {
          showSuccessMessage(
            'Success',
            `Order ${result.orderId} fulfilled successfully!`
          );
        } else {
          result.error.forEach(err =>
            showErrorMessage(err.key.toString(), err.message.toString())
          );
        }
      });
      setFulfilledOrderIds(null);
      setMultiChangeOrderStatus(true);
    } else {
      showErrorMessage('Error', 'No orders to fulfill.');
    }
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      {fulfilledOrderId != null && (
        <CommentPopup
          title="Fulfill Order"
          onSubmit={onFulfillOrderHandler}
          onClose={() => setFulfilledOrderId(null)}
        />
      )}
      {fulfilledOrderIds != null && (
        <CommentPopup
          title="Fulfill Order"
          onSubmit={onFulfillAllOrderHandler}
          onClose={() => setFulfilledOrderIds(null)}
        />
      )}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasBrandsFilter
                    hasTextSearch
                    hasDateRangeFilter
                    hasPerPageFilter
                    setCurrentPage={completedPagination.setCurrentPage}
                    setDateRange={handleDateChange}
                    disabled={isPending}
                    hasFilterByStatus
                    statusFields={COMMERCIAL_ORDER_DISPLAY_STATUS}
                    hasFilterByOrderType
                    typeFields={ORDER_TYPES}
                    hasProductsFilter
                    hasCreateItem
                    createItemLink="/commercial-orders/create"
                    hasOrderContains
                    hasFilterByPaid
                    placeholder="Search... (By invoice number, commercial customer email/title/ID, CW order ID)"
                  />
                </Col>
              </Row>
              <div className="mb-3">
                <div className={styles.subHeading}>Pending Fulfillment</div>
                <Row className="d-flex justify-content-between align-items-center mb-4 mt-1">
                  <div className={styles.selectedInformation}>
                    {selectedPendingOrders?.length
                      ? `${selectedPendingOrders?.length} of ${
                          pendingFulfillmentPagination.total_count
                        } orders
                    ${
                      selectedPendingOrders?.length === 1 ? 'is' : 'are'
                    } selected`
                      : 'No selected orders'}
                  </div>
                  {!isSalesRep && pendingActionDisabled && (
                    <div className={styles.actionColumn}>
                      <CommercialMultiActionDropdown
                        orders={pendingFulfillmentOrders}
                        orderIds={selectedPendingOrders}
                        onFulfill={() =>
                          setFulfilledOrderIds(selectedPendingOrders)
                        }
                        onPrintPackageSlip={() =>
                          handleAllPrintPackageSlip(selectedPendingOrders)
                        }
                      />
                    </div>
                  )}
                </Row>
                {pendingFulfillmentOrders.length ? (
                  <PaginationTable
                    {...fulfillmentPagination}
                    pagination={pendingFulfillmentPagination}
                  >
                    <DataTable
                      {...fulfillmentPagination}
                      sortField={fulfillmentSortField}
                      sortDirection={fulfillmentSortDirection}
                      isPending={isPending}
                      orders={pendingFulfillmentOrders}
                      onFulfill={setFulfilledOrderId}
                      openShipModal={openShipModal}
                      orderType={filters?.resourcetypes}
                      isSortable
                      areAllSelected={
                        selectedPendingOrders.length ===
                        pendingFulfillmentOrders?.length
                      }
                      selectedOrders={selectedPendingOrders}
                      updateSelectedOrders={setSelectedPendingOrders}
                      selectAllOrders={handleSelectPendingAll}
                      pending
                      downloadDisabled={pendingActionDisabled}
                    />
                  </PaginationTable>
                ) : (
                  <div className={styles.infoText}>
                    All Commercial Orders Fulfilled
                  </div>
                )}
              </div>
              <div>
                <div className={styles.subHeading}>Completed</div>
                <Row className="d-flex justify-content-between align-items-center mb-4 mt-1">
                  <div className={styles.selectedInformation}>
                    {selectedCompletedOrders?.length
                      ? `${selectedCompletedOrders?.length} of ${
                          pagination.total_count
                        } orders
                    ${
                      selectedCompletedOrders?.length === 1 ? 'is' : 'are'
                    } selected`
                      : 'No selected orders'}
                  </div>
                  {!isSalesRep && compltedActionDisabled && (
                    <div className={styles.actionColumn}>
                      <CommercialMultiActionDropdown
                        orders={orders}
                        orderIds={selectedCompletedOrders}
                        onPrintPackageSlip={() =>
                          handleAllPrintPackageSlip(selectedCompletedOrders)
                        }
                        onDownload={handleAllDownload}
                      />
                    </div>
                  )}
                </Row>
                <PaginationTable
                  {...completedPagination}
                  pagination={pagination}
                >
                  <DataTable
                    {...completedPagination}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    isPending={isPending}
                    openPaymentModal={openPaymentModal}
                    handleFetchOrderShipmentLabel={
                      handleFetchOrderShipmentLabel
                    }
                    handleFetchOrderShipmentPublicUrl={
                      handleFetchOrderShipmentPublicUrl
                    }
                    orders={orders}
                    areAllSelected={
                      selectedCompletedOrders.length === orders?.length
                    }
                    selectedOrders={selectedCompletedOrders}
                    updateSelectedOrders={setSelectedCompletedOrders}
                    selectAllOrders={handleSelectAll}
                    onDownload={handleDownload}
                    onPrintPackageSlip={handlePrintPackageSlip}
                    downloadDisabled={isReportPending || compltedActionDisabled}
                    orderType={filters?.resourcetypes}
                    isSortable
                    completed
                  />
                  {modal && (
                    <SelectPaymentMethodPopup
                      isOpen={modal}
                      setIsOpen={setModal}
                      options={ORDER_PAYMENT_OPTIONS}
                    />
                  )}

                  {showShipOrderModal && (
                    <SelectShippingPopup
                      isOpen={showShipOrderModal}
                      setIsOpen={setShowShipOrderModal}
                      withFooter={false}
                      withTotal={false}
                      setIsChargeMode={setIsChargeMode}
                      isChargeMode={isChargeMode}
                      isOnlyShipping
                      order={shipOrderDetails}
                    />
                  )}
                </PaginationTable>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

CommercialOrdersSummary.propTypes = {
  title: PropTypes.string,
};

export default CommercialOrdersSummary;
