import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import styles from './InfoSection.scss';
import classNames from 'classnames';

const InfoSection = ({ title, data, gap }) => (
  <Card className="mb-4">
    <CardBody>
      <h5 className={classNames(styles.customH5)}>{title}</h5>
      <div className={styles.infoSection}>
        {data.map(({ key, value }) => (
          <div
            key={key}
            className="d-flex justify-content-between mb-2"
            style={{ columnGap: `${gap}rem` }}
          >
            <span className={styles.key}>{key}</span>
            <span className={styles.value}>{value}</span>
          </div>
        ))}
      </div>
    </CardBody>
  </Card>
);

InfoSection.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  gap: PropTypes.string,
};

export default InfoSection;
