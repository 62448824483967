import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Table } from 'reactstrap';
import InfoSection from 'components/InfoSection/InfoSection';

const formatRowTitle = title => {
  return title
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
};

const WarehouseReturnShowForm = ({ data }) => {
  const {
    period_start,
    period_end,
    due_date,
    wine_seven_percent_return_detail,
    wine_one_percent_return_detail,
    wine_zero_percent_return_detail,
    wine_seven_percent_quantity,
    wine_one_percent_quantity,
    wine_zero_percent_quantity,
    wine_seven_percent_duty_payable,
    wine_one_percent_duty_payable,
    wine_zero_percent_duty_payable,
    spirits_more_than_seven_percent_return_detail,
    spirits_not_more_than_seven_percent_return_detail,
    spirits_more_than_seven_percent_quantity,
    spirits_not_more_than_seven_percent_quantity,
    spirits_more_than_seven_percent_duty_payable,
    spirits_not_more_than_seven_percent_duty_payable,
  } = data;

  const wineSections = [
    {
      title: 'Opening Inventory',
      rows: ['opening_inventory'],
    },
    {
      title: 'Additions',
      rows: [
        'domestic_wine',
        'imported_wine',
        'returned_from_duty_paid_sources',
        'returned_from_non_duty_paid_sources',
        'total_additions',
      ],
    },
    {
      title: 'Non Duty Paid Reductions',
      rows: [
        'removed_for_delivery_to_accredited_representatives',
        'removed_for_delivery_to_duty_free_shops',
        'removed_for_delivery_as_ships_stores',
        'removed_for_delivery_to_licensed_users',
        'removed_for_delivery_to_other_excise_warehouses',
        'exported_wine',
        'returned_to_wine_licensee',
        'breakage',
        'wine_removed_for_other_purposes_non_duty',
        'total_non_duty_paid_reductions',
      ],
    },
    {
      title: 'Duty Paid Reductions',
      rows: [
        'packaged_excluding_marked_special_containers',
        'packaged_in_marked_special_containers',
        'wine_removed_for_other_purposes_duty',
        'total_duty_paid_reductions',
      ],
    },
    {
      title: 'Total Reductions',
      rows: ['total_reductions'],
    },
    {
      title: 'Inventory Adjustment',
      rows: ['inventory_adjustment'],
    },
    {
      title: 'Closing Inventory',
      rows: ['closing_inventory'],
    },
  ];

  const spiritsSections = [
    {
      title: 'Opening Inventory',
      rows: ['opening_inventory'],
    },
    {
      title: 'Additions',
      rows: [
        'domestic_non_duty_paid_packaged',
        'imported_non_duty_paid_packaged',
        'returned_from_duty_paid_sources',
        'returned_from_non_duty_paid_sources',
        'total_additions',
      ],
    },
    {
      title: 'Non Duty Paid Reductions',
      rows: [
        'removed_for_delivery_to_registered_users',
        'removed_for_delivery_to_accredited_representatives',
        'removed_for_delivery_to_duty_free_shops',
        'removed_for_delivery_as_ships_stores',
        'removed_for_delivery_to_licensed_users_domestic',
        'removed_for_delivery_to_licensed_users_imported',
        'removed_for_delivery_to_other_excise_warehouses',
        'exported_spirits',
        'returned_to_spirits_licensee',
        'breakage',
        'spirits_removed_for_other_purposes_non_duty',
        'total_non_duty_paid_reductions',
      ],
    },
    {
      title: 'Duty Paid Reductions',
      rows: [
        'packaged_excluding_marked_special_containers',
        'packaged_in_marked_special_containers',
        'spirits_removed_for_other_purposes_duty',
        'total_duty_paid_reductions',
      ],
    },
    {
      title: 'Total Reductions',
      rows: ['total_reductions'],
    },
    {
      title: 'Inventory Adjustment',
      rows: ['inventory_adjustment'],
    },
    {
      title: 'Closing Inventory',
      rows: ['closing_inventory'],
    },
  ];

  const renderWineDetailSection = section => {
    return (
      <Card key={section.title}>
        <CardBody>
          <h4 className="card-title mb-4">{section.title}</h4>
          <Table bordered responsive>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>7%</th>
                <th>1.2%+</th>
                <th>Less than 1.2%</th>
              </tr>
            </thead>
            <tbody>
              {section.rows.map(row => (
                <tr key={row}>
                  <th>{formatRowTitle(row)}</th>
                  <td>{wine_seven_percent_return_detail[row]}</td>
                  <td>{wine_one_percent_return_detail[row]}</td>
                  <td>{wine_zero_percent_return_detail[row]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  const renderSpiritsDetailSection = section => {
    return (
      <Card key={section.title}>
        <CardBody>
          <h4 className="card-title mb-4">{section.title}</h4>
          <Table bordered responsive>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '30%' }} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>7%</th>
                <th>Less than 7%</th>
              </tr>
            </thead>
            <tbody>
              {section.rows.map(row => (
                <tr key={row}>
                  <th>{formatRowTitle(row)}</th>
                  <td>{spirits_more_than_seven_percent_return_detail[row]}</td>
                  <td>
                    {spirits_not_more_than_seven_percent_return_detail[row]}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  const renderWineDutyPayableAndQuantity = () => {
    return (
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Duty Payable and Quantity (Wine)</h4>
          <Table bordered responsive>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>7%</th>
                <th>1.2%+</th>
                <th>Less than 1.2%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Quantity</th>
                <td>{wine_seven_percent_quantity}</td>
                <td>{wine_one_percent_quantity}</td>
                <td>{wine_zero_percent_quantity}</td>
              </tr>
              <tr>
                <th>Duty Payable</th>
                <td>{wine_seven_percent_duty_payable}</td>
                <td>{wine_one_percent_duty_payable}</td>
                <td>{wine_zero_percent_duty_payable}</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  const renderSpiritsDutyPayableAndQuantity = () => {
    return (
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">
            Duty Payable and Quantity (Spirits)
          </h4>
          <Table bordered responsive>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '30%' }} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>7%</th>
                <th>Less than 7%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Quantity</th>
                <td>{spirits_more_than_seven_percent_quantity}</td>
                <td>{spirits_not_more_than_seven_percent_quantity}</td>
              </tr>
              <tr>
                <th>Duty Payable</th>
                <td>{spirits_more_than_seven_percent_duty_payable}</td>
                <td>{spirits_not_more_than_seven_percent_duty_payable}</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  return (
    <div>
      <Row>
        <Col lg="6" md="12" xl="6">
          <InfoSection
            title="Dates"
            gap="2"
            data={[
              { key: 'Period Start', value: period_start },
              { key: 'Period End', value: period_end },
              { key: 'Due Date', value: due_date },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} xl={6}>
          {/* Here is the warehouse wine return */}
          <h6 className="mb-4">Wine</h6>
          {wineSections.map(section => renderWineDetailSection(section))}
          {renderWineDutyPayableAndQuantity()}
          {/* Here is the warehouse spirits return */}
          <h6 className="mb-4">Spirits</h6>
          {spiritsSections.map(section => renderSpiritsDetailSection(section))}
          {renderSpiritsDutyPayableAndQuantity()}
        </Col>
      </Row>
    </div>
  );
};

WarehouseReturnShowForm.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WarehouseReturnShowForm;
