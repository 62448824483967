import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import InfoSection from 'components/InfoSection/InfoSection';

const Header = ({ product }) => {
  return (
    <Row>
      <Col lg="6" md="12" xl="6">
        <InfoSection
          title="Basic Info"
          gap="5"
          data={[
            { key: 'Title', value: product?.title ?? '-' },
            { key: 'Tags', value: product?.tags?.join(', ') ?? '-' },
            { key: 'Sort', value: product?.sort ?? '-' },
            {
              key: 'Volume',
              value: `${product?.volume_value}${product?.volume_unit}` ?? '-',
            },
            { key: 'Brand', value: product?.brand?.title ?? '-' },
            {
              key: 'Price',
              value: `${product?.price} ${product?.price_currency}` ?? '-',
            },
          ]}
        />
      </Col>

      {product?.can_be_a_sample && (
        <Col lg="6" md="12" xl="6">
          <InfoSection
            title="Sample Info"
            gap="5"
            data={[
              {
                key: 'Sample (Glass Pour)',
                value: product?.can_be_a_sample ? 'Yes' : 'No',
              },
              {
                key: 'Sample Price',
                value:
                  `${product?.sample_price} ${product?.sample_price_currency}` ??
                  '-',
              },
              {
                key: 'Sample Volume',
                value:
                  `${product?.sample_volume_value} ${product?.sample_volume_unit}` ??
                  '-',
              },
            ]}
          />
        </Col>
      )}

      <Col lg="6">
        <InfoSection
          title="Production Info"
          data={[
            { key: 'Residual Sugar', value: product?.residual_sugar ?? '-' },
            { key: 'Vintage', value: product?.vintage ?? '-' },
            { key: 'Lot', value: product?.lot ?? '-' },

            { key: 'Acid', value: product?.acid ?? '-' },
            { key: 'ph', value: product?.ph ?? '-' },
            { key: 'Aging', value: product?.aging ?? '-' },
            { key: 'Bottling Date', value: product?.bottling_date ?? '-' },
            { key: 'Alcohol', value: product?.alcohol ?? '-' },
          ]}
        />
      </Col>

      <Col lg="6" md="12" xl="6">
        <InfoSection
          title="Inventory & Type Info"
          data={[
            { key: 'Type', value: product?.resourcetype ?? '-' },
            {
              key: 'Total Inventory Count',
              value: product?.total_inventory_count ?? '-',
            },
            { key: 'Cellar', value: product?.cellar ?? '-' },
            {
              key: 'Container World Notification Limit',
              value: product?.container_world_notification_limit ?? '-',
            },
            {
              key: 'Can be used for subscription orders',
              value: product?.is_it_can_be_used_for_subscription_orders
                ? 'Yes'
                : 'No',
            },
            { key: 'SKU', value: product?.sku ?? '-' },
          ]}
        />
      </Col>

      <Col lg="12">
        <InfoSection
          title="Notes & Descriptions"
          gap="5"
          data={[
            { key: 'Notes', value: product?.notes ?? '-' },
            { key: 'Description', value: product?.description ?? '-' },
            { key: 'Teaser', value: product?.teaser ?? '-' },
            { key: 'Winemaker Notes', value: product?.winemaker_notes ?? '-' },
            { key: 'Food Pairing', value: product?.food_pairing ?? '-' },
            { key: 'Tasting Notes', value: product?.tasting_notes ?? '-' },
            { key: 'Production', value: product?.production ?? '-' },
          ]}
        />
      </Col>
    </Row>
  );
};

Header.propTypes = {
  product: PropTypes.object,
};

export default Header;
