import React from 'react';
import ActionDropdown from 'components/ActionDropdown';
import PropTypes from 'prop-types';
import { PAID_STATUSES } from 'constants';
import { ORDER_STATUS, ORDER_RESOURCE_TYPE } from 'constants/orders';

const DropdownItemId = Object.freeze({
  PRINT: 'print',
  DOWNLOAD: 'download',
  FULFILL: 'fulfill',
});

const CommercialMultiActionDropdown = ({
  orders,
  orderIds,
  onPrintPackageSlip,
  isDisabled,
  onFulfill,
  onDownload,
}) => {
  const filteredOrders = orders.filter(order => orderIds.includes(order.id));
  const isCommercialOrder = filteredOrders.every(
    o => o.resourcetype === ORDER_RESOURCE_TYPE.COMMERCIAL
  );
  const isStatusPaid = status => PAID_STATUSES.includes(status);
  const isPendingFulfilled = filteredOrders.every(
    o => o.status === ORDER_STATUS.PENDING_FULFILLMENT || isStatusPaid(o.status)
  );
  const dropdownItem = [
    {
      id: DropdownItemId.PRINT,
      icon: <i className="mdi mdi-package-variant mr-2 font-size-18" />,
      label: 'Print Package Slip',
    },
    {
      id: DropdownItemId.FULFILL,
      label: 'Fulfill Order',
      isHidden: !isPendingFulfilled,
    },
    {
      id: DropdownItemId.DOWNLOAD,
      icon: <i className="mdi mdi-cloud-download-outline mr-2 font-size-18" />,
      label: 'Download',
      isHidden: !isCommercialOrder || isPendingFulfilled,
    },
  ];

  const onItemClickHandler = item => {
    const itemHandlers = {
      [DropdownItemId.PRINT]: onPrintPackageSlip,
      [DropdownItemId.FULFILL]: onFulfill,
      [DropdownItemId.DOWNLOAD]: onDownload,
    };
    if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  return (
    <ActionDropdown
      items={dropdownItem}
      onItemClick={onItemClickHandler}
      isDisabled={isDisabled}
      right
    />
  );
};

CommercialMultiActionDropdown.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      resourcetype: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ),
  orderIds: PropTypes.arrayOf(PropTypes.string),
  onPrintPackageSlip: PropTypes.func,
  isDisabled: PropTypes.bool,
  onFulfill: PropTypes.func,
  onDownload: PropTypes.func,
};

export default CommercialMultiActionDropdown;
