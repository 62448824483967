export const PER_PAGE_OPTIONS = [
  { value: 10, label: 10 },
  { value: 30, label: 30 },
  { value: 100, label: 100 },
];

export const ACTIONS_OPTIONS = [
  { value: 'packing_slip', label: 'Packing Slip' },
  { value: 'shipping_label', label: 'Shipping Label' },
  { value: 'both', label: 'Both' },
];

export const ALL_ORDER_STATUSES = [
  { value: 'PAYMENT_ERROR', label: 'Payment Error' },
  { value: 'PAID_BY_CARD', label: 'Paid by Card' },
  { value: 'PAID_IN_CASH', label: 'Paid in Cash' },
  { value: 'PAID_BY_CHEQUE', label: 'Paid by Cheque' },
  { value: 'FINISHED', label: 'Finished' },
  { value: 'SHIPPED', label: 'Shipped' },
  { value: 'PARTIALLY_RETURNED', label: 'Partially Returned' },
  { value: 'RETURNED', label: 'Returned' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'PENDING_FULFILLMENT', label: 'Pending Fulfillment' },
];

export const ALL_ORDER_TYPES = [
  { value: 'TastingRoomOrder', label: 'Tasting Room Order' },
  { value: 'SubscriptionOrder', label: 'Subscription Order' },
  { value: 'RetailOrder', label: 'Dashboard Order' },
  { value: 'EcommerceOrder', label: 'Ecommerce Order' },
  { value: 'ImportedOrder', label: 'Imported Order' },
];

export const DTC_PENDING_ORDER_TYPES = [
  { value: 'SubscriptionOrder', label: 'Subscription Order' },
  { value: 'RetailOrder', label: 'Dashboard Order' },
  { value: 'EcommerceOrder', label: 'Ecommerce Order' },
  { value: 'ImportedOrder', label: 'Imported Order' },
];

export const DTC_COMPLETED_ORDER_TYPES = [
  { value: 'TastingRoomOrder', label: 'Tasting Room Order' },
  { value: 'SubscriptionOrder', label: 'Subscription Order' },
  { value: 'RetailOrder', label: 'Dashboard Order' },
  { value: 'EcommerceOrder', label: 'Ecommerce Order' },
  { value: 'ImportedOrder', label: 'Imported Order' },
];

export const DTC_PENDING_ORDER_STATUSES = [
  { value: 'PAID_BY_CARD', label: 'Paid by Card' },
  { value: 'PAID_IN_CASH', label: 'Paid in Cash' },
  { value: 'PAID_BY_CHEQUE', label: 'Paid by Cheque' },
  { value: 'PENDING_FULFILLMENT', label: 'Pending Fulfillment' },
];

export const DTC_COMPLETED_ORDER_STATUSES = [
  { value: 'FINISHED', label: 'Finished' },
  { value: 'SHIPPED', label: 'Shipped' },
];
