import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { NavLink, useLocation, useParams, Link } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import getUID from 'utils/getUID';

import { actions as ordersActions } from 'models/orders/slice';
import { actions as shipmentActions } from 'models/orderShipmentRates/slice';
import { actions as subscriptionOrdersActions } from 'models/subscriptionOrders/slice';
import {
  orderSelector,
  ordersRefundByIdSelector,
  ordersRefundPendingSelector,
  isReportPendingSelector,
  isSendBillPendingSelector,
  isPendingSelector as ordersIsPendingSelector,
} from 'models/orders/selectors';
import { hasOwnerOrManagerRoleSelector } from 'models/user/selectors';

import {
  PAYMENT_TYPES,
  ORDER_TYPES_ITEMS,
  HUMAN_ORDER_STATUSES,
  SHIPPING_STATUSES,
} from 'constants';
import { ORDER_STATUS_FOR_EDIT } from 'constants/orders';

import Main from './Main';
import OrderRefundTable from 'components/OrderRefundTable';
import {
  shipmentRatesModalOpenSelector,
  shipmentUpdatedSelector,
  shippingUpdatePendingSelector,
} from 'models/orderShipmentRates/selectors';
import styles from './OrdersShow.scss';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';
import ShipmentEditPopup from 'components/Popup/ShipmentEditPopup';
import SelectShippingPopup from 'components/Popup/SelectShippingPopup';
import SendBillPopup from 'components/Popup/SendBillPopup';
import OrderErrorsTable from 'components/OrderErrorsTable';
import InfoSection from 'components/InfoSection/InfoSection';
import formatPrice from 'utils/formatPrice';
import { prepareAddress } from 'utils/prepareAddress';
import { formatDate } from 'utils/formatDate';
import isEmpty from 'lodash/isEmpty';
import { SHIPMENT_STATUSES } from 'constants/shipment';

export const EXACT_SUCCESS_STATUSES = [
  'PAID_BY_CARD',
  'PAID_IN_CASH',
  'PAID_BY_CHEQUE',
  'PAID_BY_MONEY_TRANSFER',
  'PARTIALLY_RETURNED',
];

const statusesForSubscriptionActions = [
  'FULFILLED',
  'WAITING_FOR_APPROVAL',
  'PAYMENT_ERROR',
];

const statusesForSkip = [
  'DRAFT',
  'FULFILLED',
  'WAITING_FOR_APPROVAL',
  'CUSTOMER_APPROVED',
  'MANAGER_APPROVED',
  'PAYMENT_ERROR',
];

const finalStatuses = [
  'PAID_BY_CARD',
  'PAID_IN_CASH',
  'PAID_BY_CHEQUE',
  'PAID_BY_MONEY_TRANSFER',
  'SHIPPED',
  'RETURNED',
  'PARTIALLY_RETURNED',
  'PENDING_FULFILLMENT',
  'SKIPPED',
  'FINISHED',
  'CANCELLED',
];

const statusesForShipment = [
  'FULFILLED',
  'WAITING_FOR_APPROVAL',
  'PAYMENT_ERROR',
  'CUSTOMER_APPROVED',
  'MANAGER_APPROVED',
];

const finalShipmentStatuses = [
  SHIPMENT_STATUSES.DELIVERED,
  SHIPMENT_STATUSES.RETURNED,
];

const OrdersShow = ({ title }) => {
  const { orderID } = useParams();
  const fetchOrder = useAction(ordersActions.fetchOrder);
  const fetchReport = useAction(ordersActions.fetchCommercialReport);
  const fetchOrderRefund = useAction(ordersActions.fetchOrderRefund);
  const sendOrderBill = useAction(ordersActions.sendOrderBill);
  const sendRefundOrderBill = useAction(ordersActions.sendRefundOrderBill);
  const printFile = useAction(ordersActions.printFile);
  const updateShipping = useAction(shipmentActions.updateShipping);
  const updateShipment = useAction(shipmentActions.updateShipment);
  const setShipmentRatesModalOpen = useAction(
    shipmentActions.setShipmentRatesModalOpen
  );
  const captureSubscriptionOrder = useAction(
    subscriptionOrdersActions.captureSubscriptionOrder
  );
  const skipSubscriptionOrder = useAction(
    subscriptionOrdersActions.skipSubscriptionOrder
  );
  const orderIsPending = useSelector(ordersIsPendingSelector);
  const ordersRefundPending = useSelector(ordersRefundPendingSelector);
  const isReportPending = useSelector(isReportPendingSelector);
  const order = useSelector(orderSelector);
  const orderRefund = useSelector(ordersRefundByIdSelector(orderID));
  const shipmentRatesModalOpen = useSelector(shipmentRatesModalOpenSelector);
  const shipmentUpdated = useSelector(shipmentUpdatedSelector);
  const isSendBillPending = useSelector(isSendBillPendingSelector);
  const isUpdateShippingStatus = useSelector(shippingUpdatePendingSelector);
  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);

  const [isEditShipmentOpen, setIsEditShipmentOpen] = useState(false);
  const [isChargeMode, setIsChargeMode] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const orderShipment = [...(order?.shipping_info?.shipments ?? [])].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  )[0];

  const location = useLocation();
  const previousPath = location.state?.previousPath || '/orders/';
  const getOrderLink = () => {
    switch (order?.resourcetype) {
      case 'SubscriptionOrder':
        return `/orders/subscription/${order?.id}/edit`;
      case 'CommercialOrder':
        return `/commercial-orders/${order?.id}/edit`;
      case 'RetailOrder':
        return `/orders/${order?.id}/edit`;
      default:
        return null;
    }
  };
  const getOrderTitle = () => {
    if (!ORDER_STATUS_FOR_EDIT.includes(order?.status)) return title;
    switch (order?.resourcetype) {
      case 'SubscriptionOrder':
        return `Edit ${title}`;
      case 'CommercialOrder':
        return `Edit ${title}`;
      case 'RetailOrder':
        return `Edit ${title}`;
      default:
        return title;
    }
  };

  const getHostUserName = host => {
    const withName = host.first_name || host.last_name;
    return withName
      ? `${withName && `${host.first_name || ''} ${host.last_name || ''}`} (${
          host.email
        })`
      : host.email;
  };

  const onShipmentEdit = useCallback(
    data => {
      if (!orderShipment?.id) return;
      updateShipment({ ...data, id: orderShipment.id });
    },
    [orderShipment]
  );

  const [modalBill, setModalBill] = useState(null);
  const [modalRefundBill, setModalRefundBill] = useState(null);

  useEffect(() => {
    fetchOrder(orderID);
  }, [fetchOrder, orderID]);

  useEffect(() => {
    if (shipmentUpdated) {
      setIsEditShipmentOpen(false);
    }
  }, [shipmentUpdated]);

  useEffect(() => {
    if (isOwnerOrManagerRole) {
      fetchOrderRefund({ orderId: orderID });
    }
  }, [fetchOrderRefund, orderID, isOwnerOrManagerRole]);

  const onReportDownload = () => {
    fetchReport({ id: orderID });
  };

  const handlePrintShippingLabel = useCallback(
    parcel_id => {
      const payload = {
        orders_ids: [orderID],
        packing_slip: false,
        shipping_label: true,
        parcel_id,
      };
      printFile(payload);
    },
    [orderID]
  );

  const handleChargeOrder = () => {
    captureSubscriptionOrder({
      orderId: order?.id,
      force_capture: false,
      email: order?.shipping_info?.email || order?.customer?.email,
    });
  };

  const handleSkipOrder = () => {
    skipSubscriptionOrder(orderID);
    setConfirmModal(false);
  };

  const handleSendBill = data => {
    const payload = {
      id: data?.id,
      shipping_info: {
        email: data?.email,
      },
      payment_type: order?.payment_type,
    };

    sendOrderBill({
      data: payload,
    });
    setModalBill(false);
  };

  const handleSendRefundBill = data => {
    const payload = {
      id: data?.id,
      shipping_info: {
        email: data?.email,
      },
      refund_type: orderRefund[0]?.return_type,
    };
    sendRefundOrderBill({
      data: payload,
    });
    setModalRefundBill(false);
  };

  const isRefundButtonShow =
    (EXACT_SUCCESS_STATUSES.includes(order?.status) ||
      (order?.status === 'SHIPPED' &&
        order?.resourcetype === 'SubscriptionOrder')) &&
    order?.resourcetype !== 'ContainerWorldOrder';

  let discountValue = '-';
  if (order?.discount_percentage || order?.discount_amount) {
    discountValue = order?.discount_percentage
      ? `${order?.discount_percentage}%`
      : `${formatPrice(order?.discount_amount, order?.total_price_currency)}`;
  }

  if (orderIsPending || ordersRefundPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        link={
          ORDER_STATUS_FOR_EDIT.includes(order?.status) ? getOrderLink() : null
        }
        title={`${getOrderTitle()} - Invoice #${order?.invoice_number}`}
        breadcrumbItems={[
          { title: 'Back to orders', link: previousPath, withSearch: true },
        ]}
      />
      {modalBill && (
        <SendBillPopup
          onSubmit={handleSendBill}
          onClose={() => setModalBill(null)}
          order={modalBill}
        />
      )}
      {modalRefundBill && (
        <SendBillPopup
          onSubmit={handleSendRefundBill}
          onClose={() => setModalRefundBill(null)}
          order={modalRefundBill}
        />
      )}
      {isEditShipmentOpen && (
        <ShipmentEditPopup
          onSubmit={onShipmentEdit}
          onClose={() => setIsEditShipmentOpen(false)}
          defaultValues={orderShipment}
          brandId={order?.brand?.id}
          price={Number(order?.shipping_info?.shipments.slice(-1)[0]?.price)}
          cantPriceBeChanged={ORDER_STATUS_FOR_EDIT.includes(order?.status)}
        />
      )}
      {confirmModal && (
        <ConfirmationPopup
          active={confirmModal}
          setActive={setConfirmModal}
          title="Are you sure you want to skip this  order?"
          submitButtonTitle="Skip"
          onSaveClick={handleSkipOrder}
        />
      )}

      {shipmentRatesModalOpen && (
        <SelectShippingPopup
          isOpen={shipmentRatesModalOpen}
          setIsOpen={setShipmentRatesModalOpen}
          withFooter={false}
          setIsChargeMode={setIsChargeMode}
          isChargeMode={isChargeMode}
          captureSubscriptionOrder={handleChargeOrder}
          order={order}
        />
      )}

      <Row>
        <Col md="6">
          <InfoSection
            title="Billing Info"
            gap="5"
            data={[
              {
                key: 'Transaction Total',
                value: formatPrice(
                  order?.total_price,
                  order?.total_price_currency
                ),
              },
              {
                key: 'Tip Total',
                value: order?.is_tips_refunded
                  ? `${formatPrice(
                      order?.tips_amount,
                      order?.total_price_currency
                    )} (Refunded)`
                  : `${formatPrice(
                      order?.tips_amount,
                      order?.total_price_currency
                    )}`,
              },
              ...Object.entries(order?.applied_fees ?? {}).map(
                ([key, value]) => ({
                  key: `${key} Total`,
                  value: formatPrice(value, order?.total_price_currency),
                })
              ),
              {
                key: 'Tax Total',
                value: formatPrice(order?.fee, order?.total_price_currency),
              },
              {
                key: 'Discount Total',
                value: formatPrice(
                  order?.total_order_discount,
                  order?.total_price_currency
                ),
              },
              {
                key: 'Order Discount',
                value: discountValue,
              },
              {
                key: 'Discount',
                value: isEmpty(order?.discount_code) ? (
                  '-'
                ) : (
                  <Link to={`/discount-codes/${order?.discount_code?.id}/show`}>
                    {order?.discount_code?.title} {order?.discount_code?.coupon}
                  </Link>
                ),
              },
              ...(order?.expected_payment_type
                ? [
                    {
                      key: 'Expected Payment Type',
                      value: order?.expected_payment_type,
                    },
                  ]
                : []),
              ...(order?.payment_type
                ? [
                    {
                      key: 'Payment Type',
                      value: order?.payment_type,
                    },
                  ]
                : []),
            ]}
          />
        </Col>

        <Col md="6">
          <InfoSection
            title="Order Info"
            gap="5"
            data={[
              ...(order?.container_world_order_id
                ? [
                    {
                      key: 'Order ID',
                      value: order?.container_world_order_id,
                    },
                  ]
                : []),
              {
                key: 'Order Type',
                value: ORDER_TYPES_ITEMS[order?.resourcetype],
              },
              { key: 'Brand', value: order?.brand?.title ?? '-' },
              ...(order?.obligation
                ? [
                    {
                      key: 'Subscription name',
                      value: (
                        <Link to={`/obligation/${order?.obligation?.id}`}>
                          {order?.obligation?.tier?.title}
                        </Link>
                      ),
                    },
                    {
                      key: 'Subscription period',
                      value: order?.obligation?.tier?.period_in_months,
                    },
                    {
                      key: 'Subscription capacity',
                      value: order?.obligation?.tier?.capacity,
                    },
                  ]
                : []),
              { key: 'Warehouse', value: order?.warehouse?.title ?? '-' },
              ...(order?.host_user
                ? [
                    {
                      key: 'Wine Associate',
                      value: getHostUserName(order.host_user),
                    },
                  ]
                : []),
              {
                key: 'Order Status',
                value: HUMAN_ORDER_STATUSES[order?.status],
              },
              ...(order?.created_at
                ? [
                    {
                      key: 'Created at',
                      value: formatDate(order?.created_at),
                    },
                  ]
                : []),
              ...(order?.paid_at
                ? [
                    {
                      key: 'Paid at',
                      value: formatDate(order?.paid_at),
                    },
                  ]
                : []),
            ]}
          />
        </Col>

        <Col md="6">
          <InfoSection
            title="Shipping Info"
            gap="5"
            data={[
              ...(!orderShipment
                ? [
                    {
                      key: 'Shipping',
                      value: 'Pickup',
                    },
                  ]
                : []),
              {
                key: 'Shipping Company',
                value: orderShipment?.brand_shipping_company_title ?? '-',
              },
              {
                key: 'Shipping Price',
                value: formatPrice(
                  orderShipment
                    ? `${formatPrice(
                        Number(orderShipment?.price),
                        order?.total_price_currency
                      )} ${
                        orderShipment?.free_shipping ? '(Free shipping)' : ''
                      }`
                    : '0.00 CAD'
                ),
              },
              {
                key: 'Shipping Address',
                value: prepareAddress(order?.shipping_info),
              },
              {
                key: 'Shipping Phone',
                value: order?.shipping_info?.phone ?? '-',
              },
              {
                key: 'Shipping Email',
                value: order?.shipping_info?.email ?? '-',
              },
              {
                key: 'Shipping Status',
                value: SHIPPING_STATUSES[orderShipment?.status] ?? '-',
              },
            ]}
          />
        </Col>

        {orderShipment?.parcels?.length > 0 &&
          orderShipment.parcels.map((parcel, index) => (
            <Col md="6">
              <InfoSection
                key={parcel.id ?? index}
                gap="5"
                title={`Parcel #${index + 1}`}
                data={[
                  {
                    key: 'Tracking Number',
                    value: parcel.tracking_number ?? '-',
                  },
                  {
                    key: 'Status',
                    value: parcel.status
                      ? SHIPPING_STATUSES[parcel.status]
                      : '-',
                  },
                  {
                    key: 'Label',
                    value: parcel.label_url ? (
                      <button
                        className={styles.downloadButton}
                        onClick={() => handlePrintShippingLabel(parcel.id)}
                      >
                        Download shipping label
                      </button>
                    ) : (
                      '-'
                    ),
                  },
                  {
                    key: 'Public URL',
                    value: parcel.public_url ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={parcel.public_url}
                      >
                        {`Parcel #${index + 1} tracking URL`}
                      </a>
                    ) : (
                      '-'
                    ),
                  },
                ]}
              />
            </Col>
          ))}

        <Col md="6">
          <InfoSection
            title="Customer Info"
            data={[
              ...(order?.organization?.title
                ? [
                    {
                      key: 'Commercial Organization',
                      value: (
                        <Link
                          to={`/commercial-customers/${order?.organization?.id}`}
                        >
                          {order?.organization?.title}
                        </Link>
                      ),
                    },
                  ]
                : []),
              ...(order?.organization?.license_number
                ? [
                    {
                      key: 'License Number',
                      value: order?.organization?.license_number,
                    },
                  ]
                : []),
              ...(order?.organization?.company_type
                ? [
                    {
                      key: 'Company Type',
                      value: order?.organization?.company_type,
                    },
                  ]
                : []),
              ...(order?.organization?.address
                ? [
                    {
                      key: 'Organization Address',
                      value: prepareAddress(order?.organization?.address),
                    },
                  ]
                : []),
              {
                key: 'Name',
                value: (() => {
                  if (
                    !order?.customer &&
                    order?.resourcetype !== 'ContainerWorldOrder'
                  ) {
                    return 'Unregistered Customer';
                  }
                  if (
                    !order?.customer &&
                    order?.resourcetype === 'ContainerWorldOrder'
                  ) {
                    return 'Container World Customer';
                  }

                  if (order?.organization?.id) {
                    return (
                      <span className="text-muted">{`${order?.customer?.first_name} ${order?.customer?.last_name}`}</span>
                    );
                  }

                  return (
                    <Link to={`/wineclub-customers/${order?.customer?.id}`}>
                      {`${order?.customer?.first_name} ${order?.customer?.last_name}`}
                    </Link>
                  );
                })(),
              },
              ...(order?.customer_number
                ? [
                    {
                      key: 'Customer Number',
                      value: order?.customer_number,
                    },
                  ]
                : []),
              ...(order?.customer_type
                ? [
                    {
                      key: 'Type',
                      value: order?.customer_type,
                    },
                  ]
                : []),

              { key: 'Email', value: order?.customer?.email ?? '-' },
              { key: 'Phone', value: order?.customer?.phone ?? '-' },
            ]}
          />
        </Col>
      </Row>
      {order?.resourcetype === 'CommercialOrder' && (
        <Row className="mb-4">
          <Col md={3}>
            <Button
              disabled={isReportPending}
              onClick={onReportDownload}
              color="primary"
            >
              Download Invoice
            </Button>
          </Col>
        </Row>
      )}

      <Col className="mb-4" md={12}>
        {!!orderShipment?.id && orderShipment?.type !== 'Easypost' && (
          <Button
            className="mr-2"
            color="primary"
            onClick={() => setIsEditShipmentOpen(true)}
          >
            Edit Shipment
          </Button>
        )}
        {order?.resourcetype === 'SubscriptionOrder' &&
          statusesForShipment.includes(order?.status) && (
            <Button
              color="success"
              className="waves-effect waves-light text-nowrap mr-2"
              onClick={() => setShipmentRatesModalOpen(true)}
            >
              Select Shipment
            </Button>
          )}
        {orderShipment?.type === 'Easypost' &&
          !!orderShipment?.tracking_number &&
          !finalShipmentStatuses.includes(orderShipment?.status) && (
            <Button
              style={{ backgroundColor: '#ff7e03', borderColor: '#ff7e03' }}
              className="waves-effect waves-light text-nowrap mr-2"
              onClick={() => updateShipping(orderShipment?.id)}
              disabled={isUpdateShippingStatus}
            >
              Update Shipment Status
            </Button>
          )}
      </Col>

      {finalStatuses.includes(order?.status) &&
        order.resourcetype !== 'ContainerWorldOrder' && (
          <Button
            color="primary"
            className="waves-effect waves-light mr-3 mb-2 text-nowrap"
            onClick={() => setModalBill(order)}
            disabled={isSendBillPending}
          >
            Resend Order Receipt
          </Button>
        )}
      <Row>
        <Col md="12">
          <Main
            flavourProfile={order?.flavour_profile ?? []}
            orderItems={order?.order_items}
          />
        </Col>
      </Row>
      {order.resourcetype === 'SubscriptionOrder' &&
        statusesForSubscriptionActions.includes(order?.status) && (
          <Button
            color="primary"
            className="waves-effect waves-light mr-3 mb-2 text-nowrap "
            onClick={() => {
              setIsChargeMode(true);
              setShipmentRatesModalOpen(true);
            }}
          >
            Charge Order
          </Button>
        )}
      {order.resourcetype === 'SubscriptionOrder' &&
        statusesForSkip.includes(order?.status) && (
          <Button
            color="danger"
            className="waves-effect waves-light mr-3 mb-2 text-nowrap "
            onClick={() => setConfirmModal(true)}
          >
            Skip Order
          </Button>
        )}
      {orderRefund && (
        <Button
          color="primary"
          className="waves-effect waves-light mr-3 mb-2 text-nowrap"
          onClick={() => setModalRefundBill(order)}
          disabled={isSendBillPending}
        >
          Resend refund receipt
        </Button>
      )}
      {orderRefund && (
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <h3 className="card-title">Refunded Order Items</h3>
                {orderRefund.map(refund => (
                  <OrderRefundTable
                    className="mt-3"
                    data={refund.returned_order_items}
                    amount={+refund.return_amount}
                    comment={refund.comment}
                    date={refund.created_at}
                    invoiceNumber={refund.invoice_number}
                    shipment={+refund.shipment_return_amount}
                    tips={refund.tips_return_amount}
                    refundType={PAYMENT_TYPES[refund.return_type]?.userValue}
                  />
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {isRefundButtonShow && (
        <Row className={styles.button}>
          <Col>
            <NavLink to={`/refund/${orderID}`}>
              <Button color="primary">Refund</Button>
            </NavLink>
          </Col>
        </Row>
      )}
      {order?.errors && (
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <h3 className="card-title">System Errors</h3>
                <OrderErrorsTable
                  data={order?.errors.map(error => ({
                    id: getUID(),
                    code: Object.keys(error)[0],
                    message: Object.values(error)[0],
                  }))}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

OrdersShow.propTypes = {
  title: PropTypes.string,
};

export default OrdersShow;
