import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import InfoSection from 'components/InfoSection/InfoSection';
import { prepareAddress } from 'utils/prepareAddress';

const Header = ({ warehouse }) => {
  return (
    <>
      <Row>
        <Col lg="12" xl="6">
          <InfoSection
            title="Warehouse Information"
            data={[
              { key: 'Title', value: warehouse?.title || '-' },
              {
                key: 'Workspace Title',
                value: warehouse?.workspace_title || '-',
              },
              {
                key: 'Room',
                value: warehouse?.is_used_as_tasting_room ? 'Yes' : 'No',
              },
              ...(warehouse?.container_world_warehouse_title
                ? [
                    {
                      key: 'Warehouse Name',
                      value: warehouse.container_world_warehouse_title || '-',
                    },
                  ]
                : []),
              {
                key: 'Full Address',
                value:
                  prepareAddress({
                    country: warehouse?.country,
                    state: warehouse?.state,
                    city: warehouse?.city,
                    line1: warehouse?.line1,
                    line2: warehouse?.line2,
                    postal_code: warehouse?.postal_code,
                  }) || '-',
              },
            ]}
            gap="2"
          />
        </Col>
      </Row>
    </>
  );
};

Header.propTypes = {
  warehouse: PropTypes.object,
};

export default Header;
