import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import InfoSection from 'components/InfoSection/InfoSection';
import { terminalsLabelMap } from 'constants/terminalModels';

const Main = ({ terminal }) => {
  return (
    <>
      <Row>
        <Col lg="12" xl="6">
          <InfoSection
            title="Terminal Information"
            data={[
              { key: 'ID', value: terminal?.id || '-' },
              {
                key: 'Model',
                value: get(terminalsLabelMap, terminal?.model, 'N/A'),
              },
              { key: 'Serial Number', value: terminal?.serial_number || '-' },
              { key: 'Title', value: terminal?.title || '-' },
              {
                key: 'Warehouse',
                value: terminal?.warehouse_title ? (
                  <Link to={`/warehouses/${terminal?.warehouse_id}/show`}>
                    {terminal?.warehouse_title}
                  </Link>
                ) : (
                  '-'
                ),
              },
            ]}
            gap="2"
          />
        </Col>
      </Row>
      <Row>
        {terminal?.tables?.length > 0 && (
          <Col lg="12" xl="6">
            <InfoSection
              title="Tables"
              data={terminal?.tables.map(table => ({
                key: `Table: ${table.title}`,
                value: (
                  <Link to={`/tables/${table.id}/edit`}>
                    {`Edit ${table.title}`}
                  </Link>
                ),
              }))}
              gap="2"
            />
          </Col>
        )}
      </Row>
    </>
  );
};

Main.propTypes = {
  terminal: PropTypes.object,
};

export default Main;
